<div class="conditions-container">
    <omedom-checkbox
        class="omedom-checkBox"
        [disabled]="!client"
        [(ngModel)]="hasAgreedToShareToPro"
    >
    </omedom-checkbox>
    <div>
        <span
            >J'accepte les
            <span class="link" (click)="showMention()"
                >conditions de partage.</span
            >
        </span>
    </div>
</div>
