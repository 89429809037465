<div class="chat-message">
    <div class="chat-message-sub-container" [class.sent]="isCurrentUser">
        <!-- innert HTML -->
        <div class="chat-message-header">
            <span>
                {{ message.userEntity?.firstname }}
                {{ message.userEntity?.name }}
            </span>
            <span>{{
                timestampToDate(message.chatMessageEntity.created)
                    | date : 'short'
            }}</span>
        </div>
        <div
            class="chat-message-content"
            [innerHTML]="message.chatMessageEntity.content"
        ></div>
    </div>
</div>
