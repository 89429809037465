<div class="container">
  @if (story) {
    <div
      class="omedom-card-{{ mode }}"
      [class.padding-1]="mode !== allMode.desktop"
        [style.min-width]="
            !isPropertyCard && mode !== allMode.desktop ? '203px' : null
        "
      [style.height]="mode !== allMode.desktop ? '94px' : null"
      [class.payed]="story.isPayed"
      [class.readed]="story.isReaded && !story.isPayed"
      [class.not-payed]="!story.isPayed && story.date && story.date < now"
      >
      <div class="info flex-1">
        @if (mode !== allMode.desktop) {
          <div class="date">
            <span>{{
              story.date && !deactivateNoSmart
              ? (story.date | date : 'dd/MM/yyyy')
              : '--/--/----'
            }}</span>
          </div>
        }
        <div class="treasury">
          @if (mode === allMode.desktop) {
            <div class="verified">
              @if (
                story.isPayed ||
                (!story.isPayed && story.date && story.date < now)
                ) {
                <div
                  class="state"
                  >
                  @if (story.isPayed) {
                    <span>validé</span>
                  }
                  @if (
                    !story.isPayed && story.date && story.date < now
                    ) {
                    <span>à valider</span>
                  }
                </div>
              }
              <div class="date">
                <span>{{
                  story.date ? (story.date | date : 'dd/MM') : '--/--'
                }}</span>
              </div>
            </div>
          }
          @if (story.propertyName && mode === allMode.desktop) {
            <span
              class="property-name"
              >
              {{ story.propertyName }}
            </span>
          }
          <div class="treasury-category">
            <i
              [class]="story.categoryInfo?.icon"
              [class.margin-right-1]="mode !== allMode.desktop"
            ></i>
            @if (
              story.categoryInfo?.label &&
              mode === allMode.desktop
              ) {
              <span
                >
                {{ story.categoryInfo?.label }}
              </span>
            }
          </div>
          <strong
            class="amount-value"
                    [ngClass]="
                        story.amount &&
                        (futureCard || mode === allMode.desktop) &&
                        story.isCharge
                            ? 'charge_price'
                            : story.amount &&
                              (futureCard || mode === allMode.desktop) &&
                              !story.isCharge
                            ? 'income_price'
                            : null
                    "
            >
            @if (
              (story.amount && story.isCharge) ||
              deactivateNoSmart
              ) {
              -
              }@if (
              story.amount &&
              !story.isCharge &&
              !deactivateNoSmart
              ) {
              +
              }{{
              story.amount && !deactivateNoSmart
              ? ((story.amount | number : '0.2-2') ?? 0
              | omedomNumber)
              : '--,--'
              }}&nbsp;€
            </strong>
          </div>
          @if (!isPropertyCard) {
            <div class="movement-icon-{{ mode }}">
              <ng-container
                    [ngTemplateOutlet]="
                        story.propertyImg ? propertyImg : defaultAvatar
                    "
                >
              </ng-container>
              @if (mode !== allMode.desktop) {
                <em class="property-name">{{
                  story.propertyName
                }}</em>
              }
            </div>
          }
        </div>
        @if (mode !== allMode.desktop) {
          <div class="verified margin-left-1">
            @if (story.isPayed || (!story.isPayed && story.date < now)) {
              <div
                class="state margin-left-1"
                >
                @if (story.isPayed) {
                  <i class="uil uil-check-circle"></i>
                }
                @if (!story.isPayed) {
                  <i class="uil uil-times-circle"></i>
                }
              </div>
            }
          </div>
        }
      </div>
    }
  </div>

  <ng-template #propertyImg>
    <img
      class="header-avatar"
      [class.margin-right-1]="mode !== allMode.desktop"
      [src]="story?.propertyImg"
      />
  </ng-template>

  <ng-template #defaultAvatar>
    <ng-container
        [ngTemplateOutlet]="
            isSocietyStory
                ? defaultSocietyAvater
                : isBuildingStory
                ? defaultBuildingAvater
                : defaultPropertyAvatar
        "
    ></ng-container>
  </ng-template>

  <ng-template #defaultPropertyAvatar>
    <div class="header-icon-{{ mode }}" class="margin-right-1">
      <i class="uil uil-home"></i>
    </div>
  </ng-template>

  <ng-template #defaultSocietyAvater>
    <div class="header-icon-{{ mode }}" class="margin-right-1">
      <i class="uil uil-suitcase"></i>
    </div>
  </ng-template>

  <ng-template #defaultBuildingAvater>
    <div class="header-icon-{{ mode }}" class="margin-right-1">
      <i class="uil uil-building"></i>
    </div>
  </ng-template>
