<!-- <div class="omedom-page-container-content"> -->
<div class="help-faq-container">
  <div class="faq-item" @element>
    <div
      class="form-collapse"
      (click)="displayQuestion[0].next(!displayQuestion[0].value)"
      >
      <span>Qui est OMEDOM ?</span>
      <i
        class="uil"
        [class.uil-angle-down]="!displayQuestion[0].value"
        [class.uil-angle-up]="displayQuestion[0].value"
      ></i>
    </div>
    @if (displayQuestion[0] | async) {
      <div class="answer">
        <p>
          OMEDOM : 1er service en ligne de gestion patrimoniale pour
          faciliter les transmissions familiales.<br />
          OMEDOM a été créée en Mars 2021, à Albi dans le Tarn, par Coline
          SINQUIN.<br />
          OMEDOM s’adresse à tous les propriétaires, avec une solution qui
          permet aux utilisateurs de centraliser au même endroit
          différents actifs (immobilier, financier), piloter sa trésorerie
          en temps réel et partager son patrimoine entre tiers de
          confiance (parents, enfants, conjoints, experts...).<br />
          Nos valeurs : Sécurité - Indépendance - Honnêteté - Partage
        </p>
      </div>
    }
  </div>

  <div class="faq-item" @element>
    <div
      class="form-collapse"
      (click)="displayQuestion[1].next(!displayQuestion[1].value)"
      >
      <span>À quoi sert OMEDOM ?</span>
      <i
        class="uil"
        [class.uil-angle-down]="!displayQuestion[1].value"
        [class.uil-angle-up]="displayQuestion[1].value"
      ></i>
    </div>
    @if (displayQuestion[1] | async) {
      <div class="answer">
        <div>
          <p>
            La plateforme de gestion patrimoniale pour un suivi
            quotidien et faciliter les transmissions familiales.
          </p>
          Votre patrimoine à portée de main :
          <ul>
            <li><strong>CENTRALISER</strong> différents actifs</li>
            <li><strong>PILOTER</strong> ses finances</li>
            <li><strong>PARTAGER</strong> entre tiers</li>
          </ul>
          La Solution OMEDOM offre un ensemble de services : <br />
          <ul>
            <li>
              Centraliser des actifs différents (immo : résidences,
              locatif, sociétés) et financiers (épargnes, crédits...)
            </li>
            <li>Rassembler les documents et les ranger par bien</li>
            <li>
              Lier les mouvements bancaires pour un suivi de la
              trésorerie au quotidien
            </li>
            <li>
              Visualiser un prévisionnel de trésorerie à 12 mois,
              ventilation des dépenses et revenus
            </li>
            <li>
              Gestion simple des locations : suivi des loyers, envoi
              de quittance et lettre de relance
            </li>
            <li>Valorisation des biens en temps réel</li>
            <li>Valorisation du patrimoine financier</li>
            <li>
              DPE projeté et budgétisation des travaux pour une
              nouvelle estimation
            </li>
            <li>
              Partage entre tiers de confiance (enfants, parents,
              conjoints, experts) ou membres d’une Société immobilière
            </li>
            <li>Gestion des sociétés immobilières / immeubles</li>
          </ul>
        </div>
      </div>
    }
  </div>

  <div class="faq-item" @element>
    <div
      class="form-collapse"
      (click)="displayQuestion[2].next(!displayQuestion[2].value)"
      >
      <span>Comment activer mes alertes/notifications ?</span>
      <i
        class="uil"
        [class.uil-angle-down]="!displayQuestion[2].value"
        [class.uil-angle-up]="displayQuestion[2].value"
      ></i>
    </div>
    @if (displayQuestion[2] | async) {
      <div class="answer">
        <div>
          Rendez-vous sur votre “Compte”, dans la rubrique
          “Notifications”, vous pouvez sélectionner différents types
          d’alerte selon vos besoins.
        </div>
      </div>
    }
  </div>

  <div class="faq-item" @element>
    <div
      class="form-collapse"
      (click)="displayQuestion[3].next(!displayQuestion[3].value)"
      >
      <span>Quels sont les abonnements disponibles ?</span>
      <i
        class="uil"
        [class.uil-angle-down]="!displayQuestion[3].value"
        [class.uil-angle-up]="displayQuestion[3].value"
      ></i>
    </div>
    @if (displayQuestion[3] | async) {
      <div class="answer">
        <div>
          <span>OMEDOM propose 3 niveaux d’abonnements :</span>
          <ul class="disable-margin-top">
            <li>
              <strong>"ESSENTIAL"</strong> - Gratuit, limité à un bien
              immobilier
            </li>
            <li>
              <strong>"PREMIUM"</strong> - 95€ TTC / an ou 10€ TTC /
              mois
            </li>
            <li>
              <strong>"FAMILLE"</strong> - 449€ TTC / an ou 45€ TTC /
              mois
            </li>
            1 abonnement unique pour 5 comptes PREMIUM, dont 4 à
            partager aux membres de votre famille.
          </ul>
          <p>
            Pour plus de détails sur les fonctionnalités relatives aux
            différents types d’abonnements, rendez-vous sur votre
            “Compte”, dans la rubrique “Abonnement”, “Gérer mon
            abonnement“
          </p>
        </div>
      </div>
    }
  </div>

  <div class="faq-item" @element>
    <div
      class="form-collapse"
      (click)="displayQuestion[4].next(!displayQuestion[4].value)"
      >
      <span
        >Que signifie, partage à un tiers ou membre d’une Société
        immobilière ?</span
        >
        <i
          class="uil"
          [class.uil-angle-down]="!displayQuestion[4].value"
          [class.uil-angle-up]="displayQuestion[4].value"
        ></i>
      </div>
      @if (displayQuestion[4] | async) {
        <div class="answer">
          <div>
            <p>
              Le partage à un tiers ou membre d’une Société immobilière
              (gérant ou associé) permet de rendre visible en lecture ou
              en écriture les informations relatives à un bien immobilier,
              un immeuble ou une société immobilière.
            </p>
            <p>
              Lors d’un partage de bien, immeuble ou Société immobilière,
              il existe deux types de rôles à attribuer à un tiers ou à un
              membre d’une Société :
            </p>
            <strong>Lecteur&nbsp;:</strong>
            <ul class="disable-margin-top">
              <li>
                Visualiser les données du bien partagé (informations du
                bien, dépenses, revenus, trésorerie, date et type de
                bail, coordonnées d'un locataire)
              </li>
            </ul>
            Pour la partie 'Documents', l'abonnement 'PREMIUM' est
            nécessaire :
            <ul>
              <li>Visualiser et exporter un document du bien partagé</li>
            </ul>
            <strong>Editeur&nbsp;:</strong>
            <ul class="disable-margin-top">
              <li>
                Visualiser les données du bien partagé (informations du
                bien, charges, revenus, trésorerie, date et type de
                bail, coordonnées d'un locataire, aide)
              </li>
              <li>
                Modifier les informations relatives au bien partagé
                (fiche du bien, charges, revenus, date et type de bail,
                coordonnées d'un locataire, aide)
              </li>
              <li>
                Valider les alertes au bien partagé (charges ; revenus)
              </li>
            </ul>
            Pour les parties 'Documents' et 'Loyers', l'abonnement 'PREMIUM'
            est nécessaire&nbsp;:
            <ul class="disable-margin-top">
              <li>
                Visualiser, importer et/ou exporter un document dans le
                bien partagé
              </li>
            </ul>
            <em
              ><strong>A noter :</strong> Le partage à un tiers ou membre
              d’une Société immobilière concerne uniquement les
              informations liées au bien, immeuble ou Société immobilière
              que vous aurez sélectionné.<br />
              <strong
                >Le partage ne permettra jamais l’accès aux informations
                de votre compte OMEDOM, votre abonnement, ni vos
                informations bancaires.</strong
                ></em
                >
              </div>
            </div>
          }
        </div>
        <div class="faq-item" @element>
          <div
            class="form-collapse"
            (click)="displayQuestion[5].next(!displayQuestion[5].value)"
            >
            <span>Comment sont sécurisées mes données ?</span>
            <i
              class="uil"
              [class.uil-angle-down]="!displayQuestion[5].value"
              [class.uil-angle-up]="displayQuestion[5].value"
            ></i>
          </div>
          @if (displayQuestion[5] | async) {
            <div class="answer">
              <div>
                <p>
                  La sécurité de vos données est NOTRE priorité ! OMEDOM est
                  avant tout VOTRE coffre fort numérique !
                </p>
                <p>
                  Notre politique de confidentialité est élevée parce que,
                  chez OMEDOM, la sécurité des utilisateurs est primordiale.
                  <br />
                  Pour plus de détails, rendez-vous sur votre “Compte”&nbsp;→
                  “Mentions légales, CGV, Protection des données”&nbsp;→
                  Onglet <strong>“Politique de Confidentialité”</strong>.
                </p>
                <p>
                  Conscients de la confiance que nous portent nos
                  utilisateurs, nous sommes très vigilants sur les données
                  sensibles que nous collectons et
                  <strong
                    >aucunes données ne sont utilisées à titre collectif ni
                    commercial</strong
                    >.
                  </p>
                  <p>
                    OMEDOM investit dans des protocoles de cybersécurité afin
                    d’assurer une protection optimale de la solution.
                  </p>
                  <p>
                    Les serveurs utilisés pour conserver les données de nos
                    utilisateurs sont situés dans l’espace Européen. Les données
                    sont chiffrées par l’algorithme AES&nbsp;256, un des plus
                    sûrs.
                  </p>
                  <p>
                    Pour renforcer la sécurité de nos utilisateurs, OMEDOM donne
                    le choix d'activer le double facteur d’authentification, un
                    code d'activation est envoyé par email.
                  </p>
                  <p>
                    Enfin, OMEDOM est accompagnée par un Cabinet d’avocat
                    spécialisé en Droit des nouvelles technologies, de
                    l'informatique, de la communication et de la Protection des
                    données personn
                  </p>
                  <p>
                    Pour tout complément d’information, n’hésitez pas à nous
                    contacter : dpo&#64;omedom.com
                  </p>
                  <p>
                    Nous avons pensé OMEDOM pour les utilisateurs. Votre
                    sérénité reste notre priorité !
                  </p>
                </div>
              </div>
            }
          </div>
        </div>
