@if (story) {
  <div
    class="omedom-card padding-1"
    [class.payed]="story.isPayed && story.date <= now"
    [class.notPayed]="!story.isPayed && story.date <= now"
    [class.future]="story.date > now"
    >
    <div class="container flex-1">
      <div class="info-property flex-1">
        @if (story.propertyImg) {
          <img
            class="margin-right-1"
            [src]="story.propertyImg"
            />
        } @else {
          <div class="icon margin-right-1">
            <i class="uil uil-home"></i>
          </div>
        }
        <div class="property-title">
          <strong class="property-name">
            <i
                            [ngClass]="{
                                'uil uil-share-alt ng-star-inserted': !isOwner
                            }"
              >
            </i>
            {{ property.name }}
          </strong>
          @if (society) {
            <p class="society-name">
              {{ society.name }}
            </p>
          }
        </div>
        <strong class="amount-value margin-left-1">
          +&nbsp;{{
          (story.amount | number : '0.2') || 0 | omedomNumber
          }}&nbsp;€
        </strong>
      </div>
    </div>
    @if (receiptRelaunchButtonVisible) {
      <div
        class="actions flex-1 margin-top-1"
        >
        <button
          class="omedom-button-primary flex-1"
                [ngClass]="
                    receiptRelaunchButtonDisabled ? 'disable-cursor' : ''
                "
          [disabled]="receiptRelaunchButtonDisabled"
          (click)="onClickPDF()"
          >
          @if (!story.isPayed) {
            <i class="uil uil-file-exclamation"></i>
          }
          @if (story.isPayed) {
            <img
                    [src]="
                        '/assets/icon/quittance-' +
                        (receiptRelaunchButtonDisabled
                            ? 'disabled'
                            : 'primary') +
                        '.svg'
                    "
              />
          }
          <span>
            Générer une
            {{ story.isPayed ? 'quittance' : 'lettre de relance' }}
          </span>
        </button>
        <!-- in case of missing prerquises we explain here to the user why and what to do -->
        @if (receiptRelaunchButtonDisabled) {
          <omedom-popover
            class="margin-left-1"
            [content]="helpModalProps.title"
            icon="uil uil-exclamation-triangle"
            [withIcon]="true"
            iconColor="yellow"
            >
          </omedom-popover>
        }
      </div>
    }
  </div>
  <div class="state margin-left-1">
    @if (story.isPayed && story.date <= now) {
      <i class="uil uil-check-circle color-green"></i>
    }
    @if (!story.isPayed && story.date <= now) {
      <i class="uil uil-times-circle color-red"></i>
    }
    @if (story.date > now) {
      <i class="uil uil-question-circle color-grey"></i>
    }
    <!-- <ng-container
            *ngIf="
                (story.isPayed && story.isSentReceipt) ||
                (!story.isPayed && story.isSentRelaunch)
            "
    >
    <i class="uil uil-envelope-check"></i>
  </ng-container> -->
</div>
}
