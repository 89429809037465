<div class="padding-2">
    @if(pro){
    <!-- <div class="avatar-container">
        @if(pro.logo) {
        <img class="photo" [src]="pro?.logo" />
        } @else {
        <div class="avatar">
            <i class="uil uil-user ng-star-inserted"></i>
        </div>
        }
    </div> -->
    <div class="section color-light-black">
        <span class="label">Nom de l’entreprise</span>
        <span>
            <strong>{{ pro.name || '-' }}</strong>
        </span>
    </div>
    <div class="section color-light-black">
        <span class="label">Contact</span>
        <span>
            @if ( pro.proFirstName && pro.proName ) {
            <strong
                >{{ pro.proFirstName || '' }} {{ pro.proName || '' }}</strong
            >
            } @else {
            <strong>-</strong>
            }
        </span>
    </div>
    <div class="section color-light-black">
        <span class="label">Site web</span>
        <span>
            <strong>{{ pro.website || '-' }}</strong>
        </span>
    </div>
    @if (!pro.space) {
    <div class="section color-light-black">
        <span class="label">E-mail</span>
        <span>
            <strong>{{ pro.email || '-' }}</strong>
        </span>
    </div>
    }
    <hr class="omedom-divider" />

    <!-- <div class="section color-light-black">
        <span class="label">Domaines d'expertise</span>
        <span class="section-list">
            <strong>{{ proExpertiseField || '-' }}</strong>
        </span>
    </div> -->
    <div class="section color-light-black">
        <span class="label">Profession</span>
        <span class="section-list">
            <strong>{{ pro.position || '-' }}</strong>
        </span>
    </div>
    <div class="section color-light-black">
        <span class="label">Valeurs</span>
        <span class="section-list">
            <strong>{{ proValues || '-' }}</strong>
        </span>
    </div>
    <hr class="omedom-divider" />
    <div class="section color-light-black">
        <span class="label">Zone d'intervention</span>
        <span class="section-list">
            <strong>{{ proActivityZone || '-' }}</strong>
        </span>
    </div>
    }
</div>
