<omedom-section-header title="Liste des documents" icon="file-copy-alt">
    <omedom-input
        section-action
        icon="uil-search"
        placeholder="Rechercher ..."
        class="omedom-pro search"
        [(ngModel)]="search"
    ></omedom-input>

    @if (showCreateButton) {
    <button
        section-action
        title="Ajouter un document"
        class="omedom-icon-button-primary"
        [routerLink]="onCreateLink"
    >
        <i class="uil uil-plus"></i>
    </button>
    }
</omedom-section-header>

@if ((documents$ | async); as documents) {
<div class="list">
    @for (document of documents; track document) {
    <omedom-document-card [document]="document"></omedom-document-card>
    }
</div>
} @else {
<div class="empty">
    <p>Aucun résulat pour cette recherche.</p>
</div>
}

<ng-template #empty>
    <div class="empty">
        <p>Aucun résulat pour cette recherche.</p>
    </div>
</ng-template>
