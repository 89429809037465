<div class="propertiesFilter" (click)="presentSmartPopover($event)">
    @if (!canFilter) {
    <div class="smart">
        <i class="omedom-icon-crown"></i>
        <ion-popover
            #smartPopover
            class="omedom-popover"
            [isOpen]="isOpen"
            (didDismiss)="isOpen = false"
        >
            <ng-template>
                <div class="omedom-popover-container">
                    <span
                        >Pour filtrer vos biens et affiner votre trésorerie,
                        changez d'abonnement.</span
                    >
                    <button
                        class="omedom-icon-button-primary"
                        (click)="smartPopover.dismiss()"
                        routerLink="/tabs/menu/subscription/manage"
                    >
                        <i class="omedom-icon-crown"></i>
                    </button>
                </div>
            </ng-template>
        </ion-popover>
    </div>
    } @if (!disabled) {
    <div
        [ngClass]="{
            unlocked: canFilter,
            locked: !canFilter,
            'w-100': iconFilterToLeft,
        }"
        (click)="openSelect()"
    >
        <div class="filter-icon" [ngClass]="{ 'float-left': iconFilterToLeft }">
            <i class="uil uil-filter"></i>
            @if (iconFilterToLeft) {
            <span class="margin-left-2">Filtrez vos biens :</span>
            }
        </div>
        <omedom-select
            name="bien"
            [hidden]="true"
            #selectApp
            (selectExited)="updateFilter()"
            [(ngModel)]="selectedAssets"
            [placeholder]="subjectPlaceholder"
            [options]="(propertyOptions$ | async) ?? []"
            [isMultiple]="true"
            [disabled]="!canFilter"
        ></omedom-select>
    </div>
    }

    <div class="property-slider">
        @for (property of allPropertiesOptions; track property.id; let i =
        $index) {
        <omedom-property-filter-item
            id="{{ property.id }}"
            [asset]="property"
            [showLabel]="showLabel"
            [ngClass]="
                ((canFilter &&
                    (property.isAccesible || isBeforeTransitionDate)) ||
                    (!canFilter &&
                        !isBeforeTransitionDate &&
                        property.isAccesible)) &&
                !disabled
                    ? 'unlocked'
                    : 'locked'
            "
            (click)="propertySelected(property)"
        ></omedom-property-filter-item>
        }
    </div>
</div>
