<section class="margin-bottom-4">
  @if (clients$ | async; as clients) {
    <!-- Header display -->
    <omedom-section-header title="Liste des clients" icon="users-alt">
      <omedom-input
        section-action
        icon="uil-search"
        placeholder="Rechercher ..."
        class="omedom-pro search"
        [(ngModel)]="search"
      ></omedom-input>
      <button
        class="omedom-icon-button-secondary-outline omedom-no-margin"
        (click)="onFilter()"
        [disabled]="true"
        section-action
        >
        <i class="uil uil-filter"></i>
      </button>
      <button
        class="omedom-icon-button-primary"
        [disabled]="!pro?.licencesAvailable"
                [title]="
                    pro?.licencesAvailable
                        ? 'Activer une licence'
                        : 'Vous n\'avez plus de licence disponible'
                "
        (click)="onSend()"
        section-action
        >
        <i class="uil uil-envelope-upload"></i>
      </button>
    </omedom-section-header>
    <omedom-data-table
      [data]="getFilteredClients(clients)"
      [columns]="columns"
      [actions]="actions"
      [collapsable]="true"
      [expanded]="true"
            [disableCondition]="
                useDisabledCondition ? disabledCondition : defaultCondition
            "
      [emptyMessage]="emptyMessage"
      >
      <ng-container omedom-collapsed-header>
        {{ clients.length }} client(s) actif(s)
      </ng-container>
    </omedom-data-table>
  } @else {
    <div class="loading-container">
      <ion-spinner></ion-spinner>
    </div>
  }
</section>

<ng-template #loading>
  <div class="loading-container">
    <ion-spinner></ion-spinner>
  </div>
</ng-template>
