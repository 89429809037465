<div
    class="card-container"
    [style]=" {
        'max-width': 'min(100%, ' + ((pros.length + 1) * 300 )  + 'px)',
    } "
>
    @for(pro of pros; track pro.uid) {
    <omedom-directory-card
        [pro]="pro"
        (detailClicked)="detailClicked($event)"
    ></omedom-directory-card>
    }
    <omedom-add-pro-card></omedom-add-pro-card>
</div>
