@if (!society) {
<div class="stepper">
    <div class="step step-active" [style.flex]="activeStep === 0 ? null : 1">
        <div class="label-number">1</div>
        @if (activeStep === 0) {
        <span class="label-text">Détails</span>
        } @if (activeStep > 0) {
        <div class="label-line"></div>
        }
    </div>
    <div
        class="step"
        [style.flex]="activeStep === 1 ? null : 1"
        [class.step-active]="activeStep === 1"
    >
        @if (activeStep !== 1) {
        <div class="label-line"></div>
        }
        <div class="label-number">2</div>
        @if (activeStep === 1) {
        <span class="label-text">Membres</span>
        }
    </div>
</div>
} @else {
<omedom-sub-tab
    [subTabs]="subTabs"
    [(selectedSubTab)]="selectedSubTab"
></omedom-sub-tab>
} @switch (society ? selectedSubTab.id : activeStep) {
<!-- Detail Form -->
@case (0) { @if (detailForm) {
<form [formGroup]="detailForm" class="form">
    <div class="form-item">
        <span>Nom de la Société<sup>*</sup></span>
        <ion-item>
            <ion-label>
                <i class="uil uil-suitcase"></i>
            </ion-label>
            <ion-input
                type="text"
                formControlName="name"
                placeholder="Société"
                maxlength="30"
            ></ion-input>
        </ion-item>
    </div>
    <div class="form-item">
        <span>Type de société immobilière<sup>*</sup></span>
        <omedom-select
            formControlName="type"
            [placeholder]="typeSocietyPlaceholder"
            [options]="(typeSocietyOptions$ | async) || []"
        ></omedom-select>
    </div>
    <hr class="omedom-divider omedom-no-margin" />
    <div class="form-item">
        <span>Avatar</span>
        <div class="form-avatar-container">
            @if ( detailForm.get('photo')?.value) {
            <img
                class="form-avatar"
                alt="Avatar de la société"
                [src]="detailForm.get('photo')?.value"
            />
            } @else {
            <div class="form-default-avatar">
                <i class="uil uil-suitcase"></i>
            </div>
            }
            <button
                class="form-button-upload omedom-button omedom-button-secondary"
                (click)="uplaodAvatar()"
                type="button"
                [disabled]="pendingPhoto$.asObservable() | async"
            >
                <i class="uil uil-import"></i>
                <span>Importer une photo</span>
                @if (pendingPhoto$.asObservable() | async) {
                <ion-spinner
                    color="primary"
                    class="form-upload-spinner"
                ></ion-spinner>
                }
            </button>
        </div>
    </div>
    <hr class="omedom-divider omedom-no-margin" />
    <form [formGroup]="addressForm" class="form-inline">
        <div class="form-item">
            <span class="form-item-label">Adresse</span>
            <div class="form-flex">
                <ion-item style="width: 120px">
                    <ion-label>
                        <i class="uil uil-map-marker"></i>
                    </ion-label>
                    <ion-input
                        type="number"
                        [min]="0"
                        [max]="9999"
                        formControlName="streetNumber"
                        placeholder=""
                    ></ion-input>
                </ion-item>
                <ion-item style="width: 120px">
                    <ion-label>
                        <i class="uil uil-map-marker"></i>
                    </ion-label>
                    <ion-input
                        type="text"
                        formControlName="suffixNumber"
                        placeholder="bis, ter"
                    ></ion-input>
                </ion-item>
                <ion-item class="street-form">
                    <ion-label>
                        <i class="uil uil-map-marker"></i>
                    </ion-label>
                    <ion-input
                        type="text"
                        formControlName="street"
                        placeholder="Rue"
                    ></ion-input>
                </ion-item>
            </div>
        </div>
        <div class="form-item">
            <ion-item>
                <ion-input
                    type="text"
                    formControlName="addressLine2"
                    placeholder="Complément d'adresse"
                ></ion-input>
            </ion-item>
        </div>
        <div class="form-flex">
            <ion-item style="flex: 1">
                <ion-input
                    type="text"
                    formControlName="postalCode"
                    placeholder="Code Postal"
                    [pattern]="codepostalRegex"
                ></ion-input>
            </ion-item>
            <ion-item style="flex: 1">
                <ion-input
                    type="text"
                    formControlName="city"
                    placeholder="Ville"
                ></ion-input>
            </ion-item>
        </div>
    </form>
</form>
} @if (!society) {
<div class="form-stepper-button-container">
    <button
        class="form-stepper-button-previous omedom-button omedom-button-secondary"
        (click)="exitClicked(alertLabel, 'uil uil-suitcase', null)"
    >
        Annuler
    </button>
    <button
        class="form-stepper-button-next omedom-button omedom-button-primary"
        (click)="activeStep = 1; changeStep.emit(1)"
        [disabled]="!detailForm || detailForm.invalid"
    >
        Suivant
    </button>
</div>
} }
<!-- Settings Form -->
@case (1) {
<form class="form">
    @if (managementForm) {
    <form [formGroup]="managementForm">
        <!-- <app-radio
          label="Quel est votre statut au sein de la société ?"
          name="isUserManager"
          formControlName="isUserManager"
          [options]="yesNoOptions"
          [required]="true"
        ></app-radio> -->
        <omedom-radio
            label="Quel est votre statut au sein de la société ?"
            name="status"
            formControlName="status"
            [options]="statusOptions"
            [required]="true"
        ></omedom-radio>
        <!-- <app-info information="Cela permettra de vous donnez des droits particulier."></app-info> -->
    </form>
    }
    <hr class="omedom-divider omedom-no-margin" />
    @if (membersForm) { @for (member of membersForm.controls; track i; let i =
    $index) {
    <form class="form form-member" [formGroup]="$any(member)">
        <span class="form-member-title"> Informations du membre </span>
        <div class="form-item">
            <span>Prénom<sup>*</sup></span>
            <ion-item>
                <ion-label>
                    <i class="uil uil-user-circle"></i>
                </ion-label>
                <ion-input
                    type="text"
                    formControlName="firstname"
                    placeholder="Prénom"
                ></ion-input>
            </ion-item>
        </div>
        <div class="form-item">
            <span>Nom<sup>*</sup></span>
            <ion-item>
                <ion-label>
                    <i class="uil uil-user-circle"></i>
                </ion-label>
                <ion-input
                    type="text"
                    formControlName="name"
                    placeholder="Nom"
                ></ion-input>
            </ion-item>
        </div>
        @if ('accessManageSociety' | isAuthorised | async) {
        <div class="form-item">
            <span>Téléphone</span>
            <ion-item>
                <ion-label>
                    <i class="uil uil-phone"></i>
                </ion-label>
                <ion-input
                    type="tel"
                    formControlName="tel"
                    placeholder="Téléphone"
                ></ion-input>
            </ion-item>
        </div>
        }
        <div class="form-item-radio">
            <omedom-radio
                label="Statut du membre"
                name="status"
                formControlName="status"
                [options]="statusOptions"
                [required]="true"
            ></omedom-radio>
        </div>
        <hr class="omedom-divider omedom-no-margin" />
        <span
            class="form-member-title"
            [style.color]="
                ('accessManageSociety' | isAuthorised | async)
                    ? '#04151C'
                    : '#A8ADAF'
            "
        >
            <i class="uil uil-share-alt"></i>
            Informer et partager la société
        </span>
        @if ( !('accessManageSociety' | isAuthorised | async) ) {
        <omedom-action
            text="Pour partager la société aux membres, passez au <b>Smart</b>"
            actionIcon="omedom-icon-crown"
            [simpleIcon]="true"
        >
        </omedom-action>
        }
        <!-- <div class="form-item" *ngIf="('accessManageSociety' | isAuthorised | async)">
          <span>Email<sup>*</sup></span>
          <ion-item>
            <ion-label>
              <i class="uil uil-envelope"></i>
            </ion-label>
            <ion-input
              type="email"
              formControlName="email"
              placeholder="Email"
            ></ion-input>
          </ion-item>
        </div> -->
        @if ('accessManageSociety' | isAuthorised | async) {
        <div class="form-item">
            <span>Email<sup>*</sup></span>
            <ion-item>
                <ion-label>
                    <i class="uil uil-envelope"></i>
                </ion-label>
                <ion-input
                    type="email"
                    formControlName="email"
                    placeholder="Email"
                ></ion-input>
            </ion-item>
        </div>
        }
        <!-- // OM-816 Retirer temporairement les droits sur les membres de société -->
        <!-- <div class="form-item" *ngIf="('accessManageSociety' | isAuthorised | async)">
        <span>Rôle du membre<sup>*</sup></span>
        <app-select
          formControlName="role"
          [placeholder]="rolePlaceholder"
          [options$]="roleOptions$"
        ></app-select>
      </div> -->
        <button class="form-delete-button" (click)="removeMember(i)">
            <i class="uil uil-times-circle"></i>
        </button>
    </form>
    <hr class="omedom-divider" />
    } }
    <button
        class="omedom-button omedom-button-secondary"
        (click)="addMemberForm()"
    >
        <i class="uil uil-user-plus"></i>
        Ajouter un membre
    </button>
</form>
@if (!society) {
<div class="form-stepper-button-container">
    <button
        class="form-stepper-button-previous omedom-button omedom-button-secondary"
        (click)="activeStep = 0; changeStep.emit(0)"
    >
        Précédent
    </button>
    <button
        class="form-stepper-button-next omedom-button omedom-button-green"
        (click)="onSave()"
        [disabled]="!validity || (pending$.asObservable() | async)"
    >
        <i class="uil uil-save"></i>
        <span>Enregistrer</span>
        @if (pending$.asObservable() | async) {
        <ion-spinner color="primary" class="form-upload-spinner"></ion-spinner>
        }
    </button>
</div>
} } } @if (society) {
<div class="form-stepper-button-container">
    <button
        class="form-stepper-button-previous omedom-button omedom-button-secondary"
        (click)="exitClicked(alertLabel, 'uil uil-suitcase', null)"
    >
        Annuler
    </button>
    <button
        class="form-stepper-button-next omedom-button omedom-button-green"
        (click)="submit()"
        [disabled]="!validity || (pending$.asObservable() | async)"
    >
        <i class="uil uil-save"></i>
        <span>Enregistrer</span>
        @if (pending$.asObservable() | async) {
        <ion-spinner color="primary" class="form-upload-spinner"></ion-spinner>
        }
    </button>
</div>
}
