@if (ready$ | async) {
  <div class="card-header margin-bottom-1">
    <h3>
      <i class="omedom-icon-crown margin-right-1"></i>
      Offre découverte
    </h3>
    @if (subscription && displayPrice) {
      <span class="time">
        <i class="uil uil-clock-eight"></i>
        {{ count }} jours
      </span>
    }
  </div>
  @if (subscription?.renewDate) {
    <span class="price-renew margin-bottom-1">
      {{
      pro
      ? 'Fin de votre offre le'
      : 'Fin de votre période d’essai de ' + trialPeriod + ' le'
      }}
      {{ subscription?.renewDate }}
    </span>
  }
  <div class="w-100">
    <hr class="omedom-divider" />
    @if (familyInvitations && familyInvitations.length > 0) {
      <button
        class="omedom-button-primary w-100 margin-top-2"
        (click)="manageFam()"
        >
        <i class="uil uil-bell shake-animation"></i> Vous avez une
        invitation a rejoindre une famille
      </button>
    }
    <button
      class="omedom-button-primary w-100 margin-top-2"
      (click)="manageSub()"
      >
      Prendre un abonnement
    </button>
  </div>
  @if (pro) {
    <div class="pro">
      <span>Offert par</span>
      <img [src]="pro?.logo" class="margin-left-1" />
    </div>
  }
} @else {
  <ion-spinner></ion-spinner>
}

