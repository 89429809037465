<div class="container" [@list]="(transactionByDay$ | async)?.length">
    <ng-container [ngTemplateOutlet]="content"></ng-container>
</div>

<ng-template #content>
    @if ((treasuries$ | async); as treasuries) { @if ((transactionByDay$ |
    async); as transactionByDay) { @if (transactionByDay.length > 0) { @for
    (timeline of transactionByDay; track i; let i = $index) {
    <omedom-timeline
        [class.margin-top-2]="i !== 0"
        [date]="timeline.day"
        [@list]="transactionByDay.length"
    >
        @for (transaction of timeline.transactions; track
        trackByTransaction($index, transaction)) {
        <div [@transaction]="timeline.transactions.length">
            <omedom-treasury-card2
                [date]="timeline.day"
                [treasury]="getTreasury(transaction, treasuries)"
                [transaction]="transaction"
            ></omedom-treasury-card2>
        </div>
        }
    </omedom-timeline>
    } } @else { @switch (state$ | async) { @case ('pending') {
    <ng-container *ngTemplateOutlet="pending"></ng-container>
    } @default {
    <ng-container [ngTemplateOutlet]="empty"></ng-container>
    } } } } @else { @switch (state$ | async) { @case ('pending') {
    <ng-container *ngTemplateOutlet="pending"></ng-container>
    } @default {
    <ng-container [ngTemplateOutlet]="empty"></ng-container>
    } } } } @else { @switch (state$ | async) { @case ('pending') {
    <ng-container *ngTemplateOutlet="pending"></ng-container>
    } @default {
    <ng-container [ngTemplateOutlet]="empty"></ng-container>
    } } }
</ng-template>

<ng-template #empty>
    <div class="empty-container" @element>
        <i class="uil uil-{{ icon }} empty-icon"></i>
        <span class="empty-text">{{ message }}</span>
    </div>
</ng-template>

<ng-template #pending>
    <div class="empty-container" @element>
        <i class="uil uil-sync empty-icon"></i>
        <span class="empty-text text-center">Chargement des mouvements...</span>
        <ion-spinner></ion-spinner>
    </div>
</ng-template>
