@if (notifications.length === 0) {
<div class="empty-container">
    <div class="empty-text">Aucune notification</div>
</div>
} @if (unreadNotifications.length > 0) {
<div class="notification-title">Récentes</div>
@for (notification of unreadNotifications; track $index) {
<omedom-notification-card
    (notificationActionClick)="notificationActionClick.emit($event)"
    (openSetting)="openSetting.emit()"
    [notification]="notification"
    [hasEnableActions]="enableActions"
></omedom-notification-card>
} } @if (readedNotifications.length > 0) {
<div class="notification-title">Plus anciennes</div>
@for (notification of readedNotifications; track $index) {
<omedom-notification-card
    (notificationActionClick)="notificationActionClick.emit($event)"
    (openSetting)="openSetting.emit()"
    [notification]="notification"
    [hasEnableActions]="enableActions"
></omedom-notification-card>
} }
