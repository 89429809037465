<div class="padding-2">
    @if(pro){
    <div class="avatar-container">
        @if(pro.logo) {
        <img class="photo" [src]="pro?.logo" />
        } @else {
        <div class="avatar">
            <i class="uil uil-user ng-star-inserted"></i>
        </div>
        }
    </div>
    <div class="section color-light-black">
        <span>Nom de l’entreprise</span>
        <span>
            <strong>{{ pro.name || '-' }}</strong>
        </span>
    </div>
    <div class="section color-light-black">
        <span>Site web</span>
        <span>
            <strong>{{ pro.website || '-' }}</strong>
        </span>
    </div>
    <hr class="omedom-divider" />
    <div class="section color-light-black">
        <span>Domaines d'expertise</span>
        <span class="section-list">
            <strong>{{ proExpertiseField || '-' }}</strong>
        </span>
    </div>
    <div class="section color-light-black">
        <span>Valeurs</span>
        <span class="section-list">
            <strong>{{ proValues || '-' }}</strong>
        </span>
    </div>
    <hr class="omedom-divider" />
    <div class="section color-light-black">
        <span>Zone d'intervention</span>
        <span class="section-list">
            <strong>{{ proActivityZone || '-' }}</strong>
        </span>
    </div>
    <!-- <div class="section color-light-black">
        <span>Visio présentiel</span>
        <span>
            <strong>{{ pro?.values || '-' }}</strong>
        </span>
    </div> -->
    }
</div>
