<div class="section color-light-black">
    <span>Type de société</span>
    <span>
        <strong>{{ society?.type || '-' }}</strong>
    </span>
</div>
<div class="section color-light-black">
    <span>Adresse</span>
    <span>
        <div
            *ngIf="
                society?.address?.street ||
                    society?.address?.streetNumber ||
                    society?.address?.addressLine2 ||
                    society?.address?.city ||
                    society?.address?.postalCode;
                then address;
                else empty
            "
        ></div>
        <ng-template #address>
            <i class="uil uil-location-point margin-right-1"></i>
            <strong
                >{{ society?.address?.streetNumber || '-' }}
                {{ society?.address?.street || '-' }}</strong
            >
            <br />
            <div
                *ngIf="
                    !society?.address?.postalCode && !society?.address?.city;
                    then empty;
                    else city
                "
            ></div>
            <ng-template #city>
                <strong>
                    {{ society?.address?.addressLine2 || '-' }}
                    <br />
                    {{ society?.address?.postalCode || '-' }}
                    {{ society?.address?.city || '-' }}
                </strong>
            </ng-template>
        </ng-template>
    </span>
</div>

<ng-template #empty>
    <strong>-</strong>
</ng-template>
