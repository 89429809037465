<div class="infos">
    <div class="info layout-{{layout}}">
        <span class="title">Total remboursé</span>
        <span class="value" id="repaid">
            {{ ('accessManageFinance' | isAuthorised | async) ? (totalRepaid |
            omedomNumber) : '--,--' }} €</span
        >
    </div>

    <div class="info layout-{{layout}}">
        <span class="title">Total restant dû</span>
        <span class="value" id="remaining"
            >{{ ('accessManageFinance' | isAuthorised | async) ? (totalRemaining
            | omedomNumber) : '--,--' }} €</span
        >
    </div>
</div>

<div class="progress-bar">
    <div class="total-repaid-container" [style.width]="percentageRepaid + '%'">
        @for (loan of loans; track loan.uid) {
        <div
            class="progress-value"
            [style.width]="getPercentage(loan) + '%'"
            [style.background]="getColor(loan)"
        ></div>
        }
    </div>
</div>

<div class="legend">
    @for (loan of loans; track loan) {
    <div class="item">
        <div class="color" [style.background]="getColor(loan)"></div>
        <span class="label">{{ loan.name }}</span>
        @if (loan.interestRate) {
        <span class="interest-rate"
            >{{ ('accessManageFinance' | isAuthorised | async) ?
            (loan.interestRate | omedomNumber) : '--,--' }} %</span
        >
        } @if (loan.borrowedCapital) {
        <span class="borrowed"
            >{{ ('accessManageFinance' | isAuthorised | async) ?
            (loan.borrowedCapital | omedomNumber) : '--,--'}} €</span
        >
        }
    </div>
    }
</div>
