import { Route } from '@angular/router';
import { RouteData } from '@omedom/data';

import { AdminGuard } from './guards';

export const navigation: RouteData[] = [
    // Primary navigation
    {
        title: 'Utilisateurs',
        icon: 'users-alt',
        index: 0,
        type: 'primary',
        path: '/user',
    },
    {
        title: 'Biens',
        icon: 'home',
        index: 1,
        type: 'primary',
        path: '/property',
    },
    {
        title: 'Sociétés',
        icon: 'building',
        index: 2,
        type: 'primary',
        path: '/society',
    },
    {
        title: 'Documents',
        icon: 'file-copy-alt',
        index: 3,
        type: 'primary',
        path: '/document',
    },
    {
        title: 'Pros',
        icon: 'briefcase-alt',
        index: 4,
        type: 'primary',
        path: '/pro',
    },
    {
        title: 'Automatisations',
        icon: 'robot',
        index: 5,
        type: 'primary',
        path: '/automation',
    },
    // Secondary navigation
    {
        title: 'Développeur',
        icon: 'arrow',
        index: 1,
        type: 'secondary',
        path: '/dev',
    },
    {
        title: 'S.A.V.',
        icon: 'question-circle',
        index: 2,
        type: 'secondary',
        path: '/sav',
    },
    {
        title: 'Compte',
        icon: 'user-circle',
        index: 3,
        type: 'secondary',
        path: '/user/account',
    },
];

export const appRoutes: Route[] = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: 'user'
    },
    {
        path: 'user',
        loadChildren: () => import('./user/user.module').then(m => m.UserModule)
    },
    {
        path: 'property',
        loadChildren: () => import('./property/property.module').then(m => m.PropertyModule),
        canActivate: [AdminGuard]
    },
    {
        path: 'society',
        loadChildren: () => import('./society/society.module').then(m => m.SocietyModule),
        canActivate: [AdminGuard]
    },
    {
        path: 'document',
        loadChildren: () => import('./document/document.module').then(m => m.DocumentModule),
        canActivate: [AdminGuard]
    },
    {
        path: 'pro',
        loadChildren: () => import('./pro/pro.module').then(m => m.ProModule),
        canActivate: [AdminGuard]
    },
    {
        path: 'automation',
        loadChildren: () => import('./automation/automation.module').then(m => m.AutomationModule),
        canActivate: [AdminGuard]
    },
];
