<div
  class="content"
  [style.max-height]="isNewCgvModal || isModal ? '87vh' : 'none'"
  >
  <div class="main-title" style="margin-bottom: 10px">
    <h3>
      @if (isNewCgvModal) {
        <span>Mise à jour des nouvelles </span>
      }
      <span> Conditions Générales de Vente</span>
    </h3>
    <div>
      <em class="margin-right-1">{{ cgvCurrentDateStr }}</em>
      @if (isModal) {
        <i
          class="uil uil-times-circle close"
          (click)="modalController.dismiss()"
        ></i>
      }
    </div>
  </div>

  <h3 class="titles">1. GÉNÉRALITÉS</h3>

  <h4 class="substitles">1.1. Objet – Champ d'application</h4>
  <div class="text">
    <p>
      Les présentes conditions générales de vente (ci-après «&nbsp;les
      CGV&nbsp;») ont pour objet de définir les modalités de mise à
      disposition de la Solution OMEDOM de pilotage de patrimoine
      (ci-après «&nbsp;la Solution&nbsp;») et ses services aux
      utilisateurs, ainsi que ses conditions d'utilisation.
    </p>
    <p>
      La Solution est créée, développée et exploitée par la société par
      actions simplifiée OMEDOM, au capital social de 27458,70 €
      immatriculée au registre du commerce et des sociétés d'Albi sous le
      numéro 895 124 949, et dont le siège social est situé 72 bis, Rue du
      Commandant Blanché 81000 ALBI (ci-après «&nbsp;OMEDOM&nbsp;» ou
      «&nbsp;Nous&nbsp;»).
    </p>
    <p>
      Les présentes CGV s'appliquent à toute personne physique ou morale
      utilisant la Solution (ci-après «&nbsp;l'Utilisateur&nbsp;» ou
      «&nbsp;Vous&nbsp;»).
    </p>
    <p>
      Tout accès et/ou utilisation de la Solution suppose l'acceptation et
      le respect de l'ensemble de ces CGV. À défaut, vous ne pouvez faire
      usage de la Solution et de ses services.
    </p>
  </div>

  <h4 class="substitles">1.2. Modification des CGV</h4>
  <div class="text">
    <p>
      OMEDOM se réserve le droit de réviser et de modifier ces CGV sans
      préavis. Une notification vous informera de ce changement. Votre
      utilisation de la Solution après cette notification vaudra
      acceptation de ces changements.
    </p>
    <p>
      Les CGV applicables sont celles accessibles dans la Solution et sur
      le Site OMEDOM au jour de la souscription de Votre abonnement.
    </p>
  </div>

  <h3 class="titles">2. CONFIGURATION REQUISE</h3>

  <div class="text">
    <p>
      L'utilisation de la Solution et de ses services nécessite une
      connexion internet. Cette connexion n'est pas prise en charge par
      OMEDOM, il appartient donc à l'Utilisateur de souscrire
      préalablement à une offre internet pour mobile ou d'utiliser un
      réseau type Wifi pour pouvoir utiliser la Solution et ses services.
    </p>
  </div>

  <h3 class="titles">3. ACCÈS ET UTILISATION DE L'APPLICATION</h3>

  <h4 class="substitles">3.1. Création d'un compte</h4>
  <div class="text">
    <p>
      Afin d'accéder aux services de la Solution et souscrire les
      abonnements correspondants, Vous devez préalablement créer un compte
      Utilisateur sur la Solution, en remplissant le formulaire
      d'inscription avec les informations obligatoires et en acceptant les
      présentes CGV.
    </p>
    <p>
      Vous pouvez, en outre, accepter de recevoir des e-mails relatifs à
      l'actualité de la Solution. Vous êtes en mesure de vous désabonner,
      à tout moment, de cette newsletter en cliquant sur le lien «&nbsp;Se
      désinscrire&nbsp;» accessible en bas de chaque communication.
    </p>
  </div>

  <h4 class="substitles">3.2. Services</h4>
  <div class="text">
    <p>
      À compter de la date de création de votre compte Utilisateur, Vous
      disposez d’une «&nbsp;offre découverte&nbsp;» qui donne un accès
      gratuit à l’ensemble des fonctionnalités de la Solution pour une
      durée de quinze jours, à l’exception des fonctionnalités liées à la
      valorisation du patrimoine immobilier
    </p>
    <p>
      À la fin de cette période, Vous pourrez choisir de souscrire aux
      abonnements suivants :
    </p>
    <ul>
      <li>
        <strong>ESSENTIAL :</strong> Gratuit - fonctionnalités
        restreintes - détail abonnement ci-après.
      </li>
      <li>
        <strong>PREMIUM :</strong> 10€ TTC par mois sans engagement ou
        95€ TTC par an, avec engagement et tacite reconduction - détail
        abonnement ci-après.
      </li>
      <li>
        <strong>FAMILLE :</strong> 45€ TTC par mois, sans engagement ou
        449€ TTC par an, avec engagement et tacite reconduction - détail
        abonnement ci-après.
      </li>
    </ul>
    <p>
      L’abonnement ESSENTIAL (Gratuit) vous permet en tant qu'Utilisateur
      l’accès aux fonctionnalités/services suivants :
    </p>
    <ul>
      <li>Patrimoine immobilier : 1 bien immobilier</li>
      <ul>
        <li>Tableaux de bord (Résidences ou Parc Locatif)</li>
        <li>Paramétrage du bien</li>
        <li>Paramétrage dépenses/revenus</li>
        <li>Ventilation des charges / revenus</li>
        <li>Visualisation de la trésorerie</li>
      </ul>
      <li>Partage entre tiers (lecture seule ou édition)</li>
      <li>Paramétrage des alertes et notifications</li>
      <li>Données sécurisées</li>
      <li>Service client</li>
    </ul>

    <p>
      Les abonnements PREMIUM et FAMILLE (Payants) vous permettent en tant
      qu'Utilisateur l’accès aux fonctionnalités/services suivants :
    </p>

    <ul>
      <li>Patrimoine immobilier : biens immobiliers Illimités</li>
      <ul>
        <li>
          Tableaux de bord (Résidences ; Parc Locatif ; Sociétés
          immobilières)
        </li>
        <li>Paramétrage des biens / Immeubles / Sociétés</li>
        <li>Paramétrage dépenses/revenus</li>
        <li>Ventilation des charges / revenus</li>
        <li>
          Visualisation de la trésorerie globale et par bien,
          immeuble, société
        </li>
        <li>Prévisionnel de trésorerie</li>
      </ul>
      <li>
        Pilotage des Sociétés immobilières et accès à chaque membre de
        la société (Gérant et Associés).
      </li>
      <li>Connexion bancaire (Comptes courants)</li>
      <li>Suivi de la trésorerie en temps réel</li>
      <li>
        Patrimoine financier (Épargnes/Placements + Crédits en-cours,
        taux d’intérêts et capital restant dû)
      </li>
      <li>Partage entre tiers</li>
      <li>Centralisation des documents</li>
      <li>
        Valorisation des biens immobiliers et DPE projeté (maisons,
        appartements)
      </li>
      <li>
        Suivi de loyer, envoi de quittance et lettre de relance par le
        propriétaire à son locataire par e-mail.
      </li>
      <li>Pour l’abonnement “FAMILLE” : 5 accès PREMIUM</li>
    </ul>
    Il est précisé qu'il s'agit d'une Solution d'aide au suivi opérationnel
    du patrimoine immobilier et financier. Les services proposés ne
    constituent et ne remplacent en aucun cas des conseils ou une analyse de
    la situation patrimoniale de l'Utilisateur.

    <h3 class="titles">4. INFORMATIONS PRÉCONTRACTUELLES</h3>

    <div class="text">
      <p>
        Sont transmises à l'Utilisateur, préalablement à toute
        souscription d'abonnement à la Solution, les informations
        suivantes :
      </p>
      <ul>
        <li>
          Les caractéristiques essentielles de la Solution, à savoir
          la simplification de la gestion administrative de patrimoine
          ;
        </li>
        <li>
          Les différentes offres d'abonnement à la Solution ainsi que
          leur prix respectif.
        </li>
      </ul>
      <p>OMEDOM propose trois (3) forfaits d'abonnements :</p>
      <ul>
        <li>
          Le forfait ESSENTIAL (Gratuit) permettant à l'Utilisateur
          d'ajouter, gratuitement, un bien immobilier sur la Solution,
          et gratuitement pour piloter au quotidien l'ensemble des
          charges et revenus, et partager avec un ou plusieurs tiers.
          L'Utilisateur pourra librement et à tout moment se
          désabonner et supprimer son compte.
        </li>
        <li>
          Le forfait PREMIUM (Payant) permettant à l'Utilisateur
          d'ajouter et de piloter plusieurs biens immobiliers sur la
          Solution, sans limite du nombre de biens, ainsi que le suivi
          de son patrimoine financier. L’Utilisateur bénéficie de
          l’ensemble des fonctionnalités détaillées dans le chapitre
          3.2.
        </li>
        <li>
          Le forfait FAMILLE (Payant) permettant à l’Utilisateur de
          souscrire à un abonnement pour 5 accès en forfait PREMIUM.
        </li>
      </ul>
    </div>

    <div class="text">
      <p>
        L'Utilisateur a la possibilité de souscrire un abonnement
        directement sur la Solution, à partir du “Compte” de
        l’Utilisateur, rubrique “Abonnement”.
      </p>
      <p>
        Pour que l'abonnement soit validé, l'Utilisateur doit indiquer
        un mode de paiement.
      </p>
      <p>
        La date d'échéance de l'abonnement correspond à la date de
        réception par l'utilisateur de l'e-mail de confirmation
        d’abonnement envoyé par OMEDOM.
      </p>
      <p>
        Dans certains cas, notamment défaut de paiement, adresse erronée
        ou autre problème sur le compte de l'Utilisateur, OMEDOM se
        réserve le droit de bloquer l'abonnement de l'Utilisateur
        jusqu'à la résolution du problème.
      </p>
      <p>
        L'abonnement à la Solution est mensuel, sans engagement ou
        annuel avec engagement 12 mois. A la création de compte
        l'Utilisateur bénéficie de quinze jours d'accès gratuit, toutes
        fonctionnalités débloquées, à l’exception de la valorisation du
        patrimoine immobilier.
      </p>
      <p>
        Durant ou à l'issue des quinze jours en “Offre découverte”,
        l'Utilisateur peut décider de prendre l’abonnement PREMIUM OU
        FAMILLE (Payants) ou de basculer directement en abonnement
        ESSENTIAL (Gratuit) avec des fonctionnalités restreintes.
      </p>
      <p>
        La formule choisie, pendant ou à l’issue des quinze jours de
        gratuité, sera renouvelée chaque mois de manière tacite et
        l'Utilisateur pourra mettre fin à sa guise, conformément aux
        dispositions de l'article 10.2. sauf dans le cas où
        l’Utilisateur choisit l’abonnement annuel. Dans le cas de
        l’abonnement annuel, l’Utilisateur pourra renoncer à la tacite
        reconduction trente jours avant le renouvellement de
        l’abonnement.
      </p>
      <p>
        L'Utilisateur est informé que les quinze jours de gratuité ne
        seront pas renouvelés.
      </p>
      <div class="framed">
        <p>
          Pour toute question relative à la souscription d'un
          abonnement, l'Utilisateur doit contacter OMEDOM par e-mail à
          l'adresse contact&#64;omedom.com.
        </p>
      </div>
    </div>

    <h3 class="titles">6. PRIX</h3>

    <div class="text">
      <p>
        Le prix varie en fonction du forfait choisi par l'Utilisateur :
      </p>
      <ul>
        <li>
          Forfait ESSENTIAL : GRATUIT, limité à un bien immobilier
          Forfait PREMIUM : 10,00 € TTC par mois, sans engagement et
          sans limite de biens ou 95,00 € TTC par an, avec engagement
          12 mois et sans limite de biens
        </li>
        <li>
          Forfait FAMILLE : 5 accès en PREMIUM pour 45,00 € TTC par
          mois, sans engagement et sans limite de biens ou 449,00 €
          TTC par an, avec engagement 12 mois et sans limite de biens
        </li>
        <li>Disponible sur Web - iOS - Android</li>
      </ul>
      <table>
        <thead>
          <tr>
            <th colspan="5">
              Offre PREMIUM (sans limite de biens)
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td rowspan="2"></td>
            <td class="price-type" colspan="2">Prix TTC</td>
            <td class="price-type" colspan="2">Prix HT</td>
          </tr>
          <tr>
            <td class="price-period">Prix TTC /mois</td>
            <td class="price-period">Prix TTC total sur l'année</td>
            <td class="price-period">Prix HT /mois</td>
            <td class="price-period">Prix HT total sur l'année</td>
          </tr>
          <tr>
            <td class="subscription">
              <b>Sans engagement</b>
            </td>
            <td>10 €</td>
            <td class="price">120 €</td>
            <td>8,33 €</td>
            <td class="price">100 €</td>
          </tr>
          <tr>
            <td class="subscription">
              <b>Avec engagement 12 mois </b>
            </td>
            <td>7,92 €</td>
            <td class="price">95 €</td>
            <td>6,60 €</td>
            <td class="price">79,17 €</td>
          </tr>
        </tbody>
      </table>
      <br />
      <table>
        <thead>
          <tr>
            <th colspan="5">
              Offre FAMILLE (5 accès ; sans limite de biens)
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td rowspan="2"></td>
            <td class="price-type" colspan="2">Prix TTC</td>
            <td class="price-type" colspan="2">Prix HT</td>
          </tr>
          <tr>
            <td class="price-period">Prix TTC /mois</td>
            <td class="price-period">Prix TTC total sur l'année</td>
            <td class="price-period">Prix HT /mois</td>
            <td class="price-period">Prix HT total sur l'année</td>
          </tr>
          <tr>
            <td class="subscription">
              <b>Sans engagement</b>
            </td>
            <td>45 €</td>
            <td class="price">540 €</td>
            <td>37,50 €</td>
            <td class="price">450 €</td>
          </tr>
          <tr>
            <td class="subscription">
              <b>Avec engagement 12 mois </b>
            </td>
            <td>37,42 €</td>
            <td class="price">449 €</td>
            <td>31,18 €</td>
            <td class="price">374,17 €</td>
          </tr>
        </tbody>
      </table>
      <span
        ><em
        >Les prix sont exprimés en euros et stipulés toutes taxes
        comprises (TTC) et hors taxes (HT).</em
        ></span
        >
      </div>
      <h3 class="titles">7. PAIEMENT</h3>

      <h4 class="substitles">7.1. Moyen de paiement</h4>
      <div class="text">
        <p>
          Le moyen de paiement accepté sur la Solution est la carte
          bancaire.
        </p>
        <p>
          Il est précisé que lorsque l'Utilisateur effectue un règlement
          sur la Solution afin de payer son abonnement par carte bancaire,
          ses coordonnées bancaires ne sont ni collectées ni conservées
          par OMEDOM.
        </p>
        <p>
          Accès Web et Application Android : Pour toute information sur le
          traitement des données bancaires concernées, vous pouvez
          consulter la Politique de confidentialité de PAYPLUG ici :
          <a
            href="https://www.payplug.com/fr/politique-de-confidentialite"
            target="_blank"
            >
            https://www.payplug.com/fr/politique-de-confidentialite </a
            >.
          </p>
          <p>
            Application iOS : Pour toute information sur le traitement des
            données bancaires concernées, vous pouvez consulter la Politique
            de confidentialité d'Apple Pay :
            <a
              href="https://www.apple.com/fr/privacy/features/#apple-pay"
              target="_blank"
              >
              https://www.apple.com/fr/privacy/features/#apple-pay </a
              >.
            </p>
            <p>
              Les registres informatisés, conservés dans les systèmes
              informatiques d’OMEDOM et de ses partenaires, dans des
              conditions raisonnables de sécurité, seront considérés comme les
              preuves des communications, des commandes, des validations et
              des paiements intervenus entre l'Utilisateur et OMEDOM.
            </p>
            <p>
              Pour les transactions effectuées via la plateforme APPLE, les
              conditions générales de vente qui s'appliquent sont celles
              d'APPLE.
            </p>
          </div>

          <h4 class="substitles">7.2 Modalités de paiement</h4>
          <div class="text">
            <p>
              Le paiement s'effectuera d'avance lors de la souscription à
              l'abonnement puis à la date anniversaire de chaque mois ou de
              chaque année.
            </p>
            <p>
              Le paiement par carte bancaire du renouvellement de l'abonnement
              s'effectuera à la date d'anniversaire, chaque mois, par
              prélèvement automatique sur le mode de paiement fourni, jusqu'à
              résiliation de l'abonnement, que la résiliation soit à
              l'initiative de l'Utilisateur ou à celle D'OMEDOM, dans les
              conditions prévues à l'article 11 ci-dessous.
            </p>
            <p>
              Aucun remboursement (correspondant à une partie de la période de
              l'abonnement) ne sera admis en cas de résiliation en cours de
              période souscrite, sauf en cas d’abonnement à une offre
              supérieure.
            </p>
            <p>
              Pour les paiements effectués via la plateforme APPLE, les
              conditions générales de vente qui s'appliquent sont celles
              d'APPLE.
            </p>
          </div>

          <h4 class="substitles">7.3 Autorisation de prélèvement</h4>
          <div class="text">
            <p>
              Pour les renouvellements d'abonnement par carte bancaire, OMEDOM
              et, le cas échéant, le prestataire de paiement désigné sont
              autorisés par l'Utilisateur à débiter le même moyen de paiement
              que celui utilisé pour la souscription de l'abonnement initial.
            </p>
            <p>
              En cas de reconduction tacite, le tarif en vigueur à la date du
              renouvellement pour l'abonnement concerné sera pleinement
              applicable.
            </p>
            <p>
              En cas d'échec de paiement du renouvellement de l'abonnement
              mensuel, OMEDOM se réserve le droit de reporter le paiement
              ayant fait défaut au mois suivant pour lequel un paiement double
              de l'abonnement sera dû dans les 30 jours à compter de la date
              de renouvellement de l'abonnement. En cas de second échec de
              paiement OMEDOM se réserve le droit de suspendre ou résilier
              l'abonnement de l'Utilisateur.
            </p>
            <p>
              En cas d'échec de paiement du renouvellement de l'abonnement
              annuel, OMEDOM se réserve le droit de reporter le paiement ayant
              fait défaut au mois suivant. En cas de second échec de paiement
              OMEDOM se réserve le droit de suspendre ou résilier l'abonnement
              de l'Utilisateur.
            </p>
            <p>
              L'Utilisateur est responsable de tous les éventuels frais
              applicables et de tous les abonnements achetés. Dans l'hypothèse
              où OMEDOM ne pourrait prélever à l'échéance convenue le montant
              de votre abonnement, Vous restez redevable des échéances
              précédentes et OMEDOM se réserve tous droits et actions à cet
              égard.
            </p>
            <p>
              Pour les autorisations de prélèvement via la plateforme APPLE,
              les conditions générales de vente qui s'appliquent sont celles
              d'APPLE.
            </p>
          </div>

          <h3 class="titles">8. OBLIGATIONS D'OMEDOM</h3>
          <div class="text">
            <p>
              Les engagements D'OMEDOM constituent une obligation de moyens au
              terme de laquelle les services seront prestés dans le strict
              respect des règles professionnelles en usage et conformément aux
              présentes CGV.
            </p>
            <p>
              OMEDOM ne fournit ni conseil ni analyse patrimoniale et ne peut
              être responsable des calculs, rapports, déclarations, et
              documents administratifs générés dans la mesure où ceux-ci sont
              fondés sur les informations communiquées à OMEDOM par
              l'Utilisateur. L'Utilisateur est donc seul responsable des
              informations qu'il fournit à OMEDOM et des documents générés par
              la Solution.
            </p>
            <p>
              OMEDOM s'efforce d'offrir les meilleurs services possibles et ne
              peut garantir que ceux-ci seront toujours disponibles et sans
              défaut et répondront à vos besoins et attentes.
            </p>
            <p>
              Si le besoin s'en fait sentir, Nous suspendrons temporairement
              l'accès à la Solution et à ses services afin de régler le
              problème. OMEDOM ne pourra être tenue responsable au cas où les
              services de la Solution seraient indisponibles pendant une
              certaine période.
            </p>
            <p>
              OMEDOM se réserve le droit de restreindre occasionnellement
              l'accès à la Solution pour permettre des réparations,
              l'entretien ou la mise en place de nouveaux services et de
              manière générale, toute opération de maintenance prévue ou non.
            </p>
            <p>
              Le contenu de la Solution est susceptible d'évoluer. OMEDOM se
              réserve la possibilité de supprimer, sans préavis et sans
              recours de votre part, certaines de ses fonctionnalités.
            </p>
          </div>

          <h3 class="titles">9. OBLIGATIONS DE L'UTILISATEUR</h3>
          <h4 class="substitles">9.1 Identifiant et mot de passe</h4>
          <div class="text">
            <p>
              L'identifiant et le mot de passe que Vous avez choisis lors de
              la création de votre compte Utilisateur vous sont propres,
              personnels et confidentiels. À ce titre, Vous vous engagez à ne
              pas les divulguer sous quelque forme que ce soit.
            </p>
            <p>
              La conservation, l'utilisation et la transmission de votre
              identifiant et de votre mot de passe s'effectuent sous votre
              entière et unique responsabilité.
            </p>
            <p>
              Toute utilisation de votre identifiant et de votre mot de passe
              sera présumée avoir été effectuée ou dûment autorisée par Vous.
            </p>
            <p>
              OMEDOM ne saurait en aucun cas être tenue responsable des
              conséquences préjudiciables résultant d'utilisations illicites,
              frauduleuses ou abusives de votre identifiant et de votre mot de
              passe. Elle ne saurait davantage être tenue responsable de
              l'accès à votre compte Utilisateur par un tiers non expressément
              autorisé par Vous résultant de votre faute ou de votre
              négligence.
            </p>
            <div class="framed">
              <p>
                En cas de vol ou de détournement de votre identifiant et de
                votre mot de passe, il vous appartient de nous avertir
                immédiatement, par mail à l'adresse :
                <a href="mailto:serviceclient@omedom.com" target="_blank"
                  >serviceclient&#64;omedom.com</a
                  >.
                </p>
                <p>
                  En cas d'impossibilité de connexion avec votre identifiant
                  et votre mot de passe, Vous devez nous avertir sans délai
                  par e-mail à l'adresse suivante :
                  <a href="mailto:serviceclient@omedom.com" target="_blank"
                    >serviceclient&#64;omedom.com</a
                    >.
                  </p>
                </div>
                <p>
                  Vous ne pouvez transférer votre compte à quiconque sans d'abord
                  obtenir notre permission écrite, et vous vous interdisez
                  d'utiliser ou d'usurper l'identité d'autrui.
                </p>
              </div>
              <h4 class="substitles">9.2. Garanties</h4>
              <div class="text">
                <p>
                  En créant un compte Utilisateur sur la Solution et en utilisant
                  ses services, Vous garantissez et Vous engagez à ce que :
                </p>
                <p>
                  Les informations que Vous fournissez vous sont personnelles,
                  sont exactes et que Vous n'utilisez pas les informations
                  personnelles d'autrui sans autorisation ;
                </p>
                <p>
                  Vous êtes légalement capable de conclure des engagements
                  juridiques ;
                </p>

                <p>
                  Vos informations personnelles seront mises à jour si nécessaire
                  ;
                </p>
                <p>
                  L'utilisation que vous ferez des services et de la Solution soit
                  conforme aux présentes CGV, ainsi qu'aux lois et règlements en
                  vigueur.
                </p>
                <div class="framed">
                  <p>
                    Si une erreur se produit dans les services de la Solution,
                    merci de le signaler à l'adresse suivante :
                    <a href="mailto:serviceclient@omedom.com" target="_blank"
                      >serviceclient&#64;omedom.com</a
                      >.
                    </p>
                  </div>
                </div>

                <h3 class="titles">
                  10. RÉCLAMATION – RÉSILIATION – DROIT DE RÉTRACTATION
                </h3>

                <h4 class="substitles">10.1 Réclamation</h4>
                <div class="text">
                  <p>
                    Vous pouvez adresser vos réclamations par e-mail à l'adresse
                    suivante :
                    <a href="mailto:serviceclient@omedom.com" target="_blank"
                      >serviceclient&#64;omedom.com</a
                      >.
                    </p>
                  </div>

                  <h4 class="substitles">10.2 Résiliation par l'Utilisateur</h4>
                  <div class="text">
                    <p>
                      Dans le cas d’un abonnement mensuel, Vous pouvez à tout moment
                      résilier votre abonnement mensuel en accédant aux paramètres de
                      votre compte sur la Solution.
                    </p>
                    <p>
                      La résiliation sera effective à la fin de la période mensuelle
                      en cours.
                    </p>
                    <p>
                      Dans le cas d’un abonnement annuel, Vous pouvez résilier à tout
                      moment votre abonnement annuel avant la date d’anniversaire de
                      renouvellement en accédant aux paramètres de votre compte sur la
                      Solution.
                    </p>
                    <p>
                      La résiliation sera effective à la fin de la période annuelle en
                      cours.
                    </p>
                  </div>

                  <h4 class="substitles">10.3 Résiliation par OMEDOM</h4>
                  <div class="text">
                    <p>
                      Nous pouvons suspendre ou résilier, sans préavis, votre accès à
                      la Solution à la suite d'une fraude ou une infraction aux lois
                      et règlements ou d'un manquement à une obligation découlant des
                      présentes CGV y incluant, sans limitation, l'impossibilité de
                      prélever le montant de votre abonnement à l'échéance convenue.
                    </p>
                  </div>

                  <h4 class="substitles">10.4 Absence de droit de rétractation</h4>
                  <div class="text">
                    <p>
                      Conformément aux dispositions de l'article L.121-21-8 du code de
                      la consommation, Vous reconnaissez expressément que l'exécution
                      du contrat commence dès votre souscription à un abonnement et
                      acceptez, en conséquence, de ne pas bénéficier du droit de
                      rétractation lié à la vente en ligne ou à distance.
                    </p>
                    <p>
                      En conséquence, aucune demande de rétractation, d'annulation ou
                      de remboursement ne sera recevable pour la période souscrite.
                    </p>
                  </div>

                  <h3 class="titles">11. Propriété intellectuelle</h3>

                  <div class="text">
                    <p>
                      La structure et le contenu de la Solution (arborescence,
                      graphisme, textes, logos, icônes-boutons, logiciels, etc.)
                      demeure la propriété exclusive D'OMEDOM.
                    </p>
                    <p>
                      Conformément aux articles L.111-1 et L.123-1 du code de la
                      propriété intellectuelle, toute reproduction, modification,
                      adaptation, traduction, utilisation commerciale, totale ou
                      partielle, publication, concession sous-licence, transfert ou
                      vente, quel que soit le moyen, de tout ou partie de la Solution
                      est strictement interdit, sauf autorisation expresse, écrite et
                      préalable d'OMEDOM.
                    </p>
                    <p>
                      OMEDOM consent à l'Utilisateur une licence temporaire
                      d'utilisation strictement limitée aux actes nécessaires pour
                      consulter la Solution, aux fins de bénéficier des services qui y
                      sont proposés et ce, à l'exclusion de toute autre utilisation.
                    </p>
                    <p>L'Utilisateur s'engage notamment à ne pas :</p>
                    <ul>
                      <li>
                        Intégrer tout ou partie du contenu de la Solution dans un
                        site tiers, à des fins commerciales ou non ;
                      </li>
                      <li>
                        Utiliser tout moyen permettant de récupérer ou d'indexer
                        tout ou partie du contenu de la Solution, excepté en cas
                        d'autorisation expresse et préalable D'OMEDOM ;
                      </li>
                      <li>
                        Copier les informations sur des supports de toute nature
                        permettant de reconstituer tout ou partie des fichiers
                        d'origine.
                      </li>
                    </ul>
                    <p>
                      Tout manquement à ce qui précède est susceptible d'engager votre
                      responsabilité, sans préjudice de notre droit de résilier
                      unilatéralement votre abonnement conformément aux termes de
                      l'article 11.3 ci-dessus.
                    </p>
                  </div>

                  <h3 class="titles">12. DONNÉES PERSONNELLES</h3>

                  <div class="text">
                    <p>
                      Les données à caractère personnel ainsi recueillies font l'objet
                      d'un traitement réalisé par le Vendeur, qui agit en tant que
                      responsable de traitement. Ce traitement s'effectue dans le
                      strict respect de la réglementation relative à la protection des
                      données personnelles et notamment du Règlement Général sur la
                      Protection des Données (RGPD).
                    </p>
                    <p>
                      L'Utilisateur est informé que, lors de la création de son compte
                      sur la Solution, des données à caractère personnel le concernant
                      sont collectées et traitées par OMEDOM.
                    </p>
                    <p>
                      Tout refus ou opposition au traitement de ces données ne
                      permettra pas à OMEDOM de fournir les services et d'assurer
                      l'utilisation optimale de la Solution.
                    </p>
                    <p>
                      Les données personnelles de l'Utilisateur sont traitées
                      notamment pour l'administration et la gestion des services
                      proposés par la Solution.
                    </p>
                    <p>
                      Les données personnelles sont conservées par OMEDOM pour la
                      durée nécessaire à l'accomplissement des finalités pour
                      lesquelles elles ont été collectées, sous réserve toutefois des
                      possibilités d'archivage, des obligations de conservation
                      prévues par la loi et/ou la réglementation de certaines données
                      et/ou d'anonymisation.
                    </p>
                    <p>
                      Conformément à la réglementation applicable, l'Utilisateur
                      dispose d'un droit d'accès, de rectification, d'effacement et
                      d'opposition au traitement de ses données, d'un droit à la
                      limitation du traitement et à la portabilité des données le
                      concernant, du droit de retirer le consentement au traitement de
                      ses données, du droit de décider du sort de ses données après
                      son décès, ainsi que du droit d'introduire une réclamation
                      auprès de la CNIL.
                    </p>
                    <div class="framed">
                      <p>
                        L'Utilisateur peut exercer ses droits auprès du DPO d'OMEDOM
                        en adressant une demande par e-mail à l'adresse suivante :
                        dpo&#64;omedom.com, ou en envoyant un courrier à l'adresse
                        suivante : OMEDOM/DPO - 72 bis, Rue du Commandant Blanché
                        81000 ALBI.
                      </p>
                    </div>
                    <p>
                      Pour plus d'informations sur les opérations de traitement
                      effectuées par OMEDOM ainsi que les droits de l'Utilisateur sur
                      ses données personnelles, l'Utilisateur est invité à consulter
                      la Politique de protection des données d'OMEDOM - rendez-vous
                      dans l'onglet "Politique de confidentialité".
                    </p>
                  </div>

                  <h3 class="titles">13. CESSIBILITÉ</h3>
                  <div class="text">
                    <p>
                      L'Utilisateur n'est pas autorisé à transférer ses droits et
                      obligations en vertu des présentes CGV à un tiers, sauf accord
                      préalable et écrit D'OMEDOM.
                    </p>
                  </div>

                  <h3 class="titles">14. DISPOSITIONS GÉNÉRALES</h3>
                  <h4 class="substitles">14.1 Sous-traitance</h4>
                  <div class="text">
                    <p>
                      OMEDOM se réserve le droit de sous-traiter tout ou partie de
                      l'exécution de ses obligations à des prestataires répondant aux
                      mêmes exigences de qualité et de qualification.
                    </p>
                  </div>

                  <h4 class="substitles">14.2 Force majeure</h4>
                  <div class="text">
                    <p>
                      Toutes circonstances indépendantes de la volonté des parties,
                      empêchant l'exécution dans des conditions normales de leurs
                      obligations, sont considérées comme des causes d'exonération des
                      obligations des parties et peuvent entraîner leur suspension.
                    </p>
                    <p>
                      La partie qui invoque les circonstances visées ci-dessus doit
                      immédiatement avertir l'autre partie de leur survenance, ainsi
                      que de leur disparition.
                    </p>
                    <p>
                      Seront considérés comme cas de force majeure tous faits ou
                      circonstances irrésistibles, extérieurs aux parties,
                      imprévisibles, inévitables, indépendants de la volonté des
                      parties et qui ne pourront être empêchés par ces dernières,
                      malgré tous les efforts raisonnablement possibles. aux clients.
                    </p>
                    <p>
                      Les parties se rapprocheront pour examiner l'incidence de
                      l'événement et convenir des conditions dans lesquelles
                      l'exécution du contrat sera poursuivie. Si le cas de force
                      majeur a une durée supérieure à trois (3) mois, les présentes
                      CGV pourront être résiliées par la partie lésée par
                      l'inexécution.
                    </p>
                  </div>

                  <h4 class="substitles">14.3 Indépendance des clauses</h4>
                  <div class="text">
                    <p>
                      Si une ou plusieurs stipulations des présentes conditions
                      générales sont tenues pour non valides ou déclarées comme telles
                      en application d'une loi, d'un règlement ou à la suite d'une
                      décision définitive d'une juridiction compétente, les autres
                      stipulations garderont toute leur force et leur portée et le
                      fait pour l'une des parties de ne pas se prévaloir d'un
                      manquement par l'autre partie à l'une quelconque des obligations
                      visées dans les présentes conditions générales ne saurait être
                      interprété pour l'avenir comme une renonciation à l'obligation
                      en cause.
                    </p>
                  </div>

                  <h4 class="substitles">14.4 Loi applicable</h4>
                  <div class="text">
                    <p>
                      Les présentes CGV sont soumises à la Solution du droit français.
                      Elles sont rédigées en langue française. Dans le cas où elles
                      seraient traduites en une ou plusieurs langues, seul le texte
                      français ferait foi en cas de litige.
                    </p>
                  </div>

                  <h4 class="substitles">14.5. Litiges, médiation et juridiction</h4>
                  <div class="text">
                    <p>
                      En cas de difficultés sur la formation, la validité,
                      l'interprétation, l'exécution, l'inexécution ou l'extinction des
                      présentes CGV, les parties s'efforceront de résoudre leur
                      différend à l'amiable.
                    </p>
                    <p>
                      En cas de difficulté ou de réclamation, l'Utilisateur est invité
                      à contacter préalablement OMEDOM par e-mail à l'adresse suivante
                      :
                      <a href="mailto:serviceclient@omedom.com" target="_blank"
                        >serviceclient&#64;omedom.com</a
                        >
                        ou en envoyant un courrier à l'adresse suivante :
                        OMEDOM/Réclamations - 72 bis, Rue du Commandant Blanché 81000
                        ALBI.
                      </p>
                      <p>
                        Conformément à l'article L.612-1 du code de la consommation : «
                        <em
                          >Tout consommateur a le droit de recourir gratuitement à un
                          médiateur de la consommation en vue de la résolution amiable
                          du litige qui l'oppose à un professionnel
                        </em>
                        &nbsp;».
                      </p>
                      <p>
                        Les litiges entrant dans le champ d'application de l'article
                        L.612-1 du code de la consommation sont les litiges définis à
                        l'article L.611-1 du code de la consommation, à savoir les
                        litiges de nature contractuelle, portant sur l'exécution d'un
                        contrat de vente ou de fourniture de services, opposant un
                        consommateur à un professionnel. Le texte couvre les litiges
                        nationaux et les litiges transfrontaliers.
                      </p>
                      <p>
                        Le consommateur ne peut toutefois saisir le médiateur qu'à la
                        condition d'avoir préalablement fait une réclamation écrite
                        auprès d'OMEDOM, telle que décrite ci-dessus. Si cette démarche
                        n'est pas effectuée, la demande de médiation sera irrecevable.
                      </p>
                      <p>
                        En l'absence de réponse ou de réponse satisfaisante dans l'année
                        suivant l'envoi de la réclamation à OMEDOM, l'Utilisateur pourra
                        faire examiner sa demande par un médiateur de la consommation,
                        dont les coordonnées sont reproduites ci-dessous :<br />
                        SAS Médiation Solution <br />
                        222 chemin de la bergerie <br />
                        01800 Saint Jean de Niost <br />
                        Site :
                        <a
                          href="https://www.sasmediationsolution-conso.fr"
                          target="_blank"
                          >https://www.sasmediationsolution-conso.fr</a
                          ><br />
                          email :
                          <a
                            href="mailto:contact@sasmediationsolution-conso.fr"
                            target="_blank"
                            >contact&#64;sasmediationsolution-conso.fr</a
                            >
                          </p>
                          <p>
                            Toutes les informations sur la réglementation relative à la
                            médiation consommation et les modalités de cette médiation
                            peuvent être consultées ici :
                            <a
                              href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&lng=FR"
                              target="_blank"
                              >
                              https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&lng=FR</a
                              >.
                            </p>
                            <p>
                              Tout différend persistant entre les parties sera soumis à la
                              compétence exclusive du Tribunal Judiciaire du domicile du
                              consommateur, sauf règles impératives contraires.
                            </p>
                          </div>
                        </div>
                      </div>
