<div
    class="subscription-current-container"
    [style.background-color]="currentConfiguration?.color"
>
    <div class="card-header margin-bottom-1">
        @if (configurations && subscription?.futureRank) {
        <h3>
            <i class="omedom-icon-crown margin-right-1"></i>
            {{ subscription?.futureRank! | capitalizeFirstLetter }}
        </h3>
        } @if (!configurations && subscription?.rank) {
        <h3>
            <i class="omedom-icon-crown margin-right-1"></i>
            {{ subscription?.rank! | capitalizeFirstLetter }}
        </h3>
        } @if ( subscription && subscription.rank! !==
        SubscriptionRank.ESSENTIAL && !subscription.fromFamily ) { @if
        (!subscription?.futureRank || configurations) {
        <span class="price"
            >{{ currentConfiguration?.price?.[subscription.subscriptionType] | payplugNumberToPrice

            }}€ TTC /
            {{
                SubscriptionType.MONTHLY === subscription.subscriptionType
                    ? 'mois'
                    : 'an'
            }}</span
        >
        } }
    </div>

    @if ( subscription?.renewDate && !subscription?.fromFamily &&
    subscription?.isSubscribed ) {
    <span class="price-renew margin-bottom-1">
        {{
            subscription?.canceled ||
            subscription?.trial ||
            (!configurations && subscription?.futureRank)
                ? 'Fin de votre offre'
                : 'Renouvellement automatique'
        }}
        le
        {{ subscription?.renewDate! | date : 'dd/MM/yyyy' }}
    </span>
    } @if (!currentConfiguration?.isActive) {
    <p class="color-red">
        Attention votre abonnement
        {{ currentConfiguration?.docName! | capitalizeFirstLetter }} n'est plus
        disponible, si vous l'annulez vous ne pourrez plus le reprendre.
    </p>
    } @if (subscription?.fromFamily) {
    <span class="price-renew margin-bottom-1">
        Abonnement FAMILLE familial fournit par
        {{ subscription?.fromFamily?.firstName }}
        {{ subscription?.fromFamily?.name }}
    </span>
    } @if (!configurations) {
    <div>
        <hr class="omedom-divider" />
        @if (familyInvitations && familyInvitations.length > 0) {
        <button
            class="omedom-button-primary w-100 margin-top-2"
            (click)="manageFamilyInvitations.emit()"
        >
            <i class="uil uil-bell shake-animation"></i> Vous avez une
            invitation a rejoindre une famille
        </button>
        } @if (!subscription?.fromFamily) {
        <button
            class="omedom-button-primary w-100 margin-top-2"
            (click)="manageSubscription.emit()"
        >
            Gérer mon abonnement
        </button>
        } @if (subscription?.rank! === SubscriptionRank.FAMILY) {
        <button
            class="omedom-button-primary w-100 margin-top-2"
            (click)="manageFamily.emit()"
        >
            Gérer ma famille
        </button>
        } @if (subscription?.fromFamily) {
        <button
            class="omedom-button-secondary w-100 margin-top-2"
            (click)="leaveFamily.emit()"
        >
            Quitter l'abonnement Famille
        </button>
        }
        <button
            class="omedom-button-secondary w-100 margin-top-2"
            (click)="paymentHistory.emit()"
        >
            <i class="uil uil-receipt"></i>
            Historique des paiements et factures
        </button>
    </div>
    }
</div>
