<!-- <ion-content [fullscreen]="true"> -->

@if (pro && user) {
  <div class="auth">
    <h2>OMEDOM vous a été fourni par</h2>
    <img class="logo" [src]="pro.logo" />
    <omedom-info
      class="w-100 margin-bottom-1"
      iconStart="uil uil-shield-check"
      information="OMEDOM est une application indépendante, vos données vous appartiennent"
    ></omedom-info>
    <div>
      En acceptant les
      <span class="link" (click)="showMention()">conditions de partage</span>,
      vous bénéfierez d'un abonnement annuel prenium d'un an
    </div>
    <div class="omedom-sticky-button-container w-100 top-100">
      <button
        class="omedom-button-secondary flex-1"
        (click)="refuseLicence()"
        [disabled]="pending$ | async"
        >
        Refuser
        <!-- <ion-spinner *ngIf="pending$ | async"></ion-spinner> -->
      </button>
      <button
        class="omedom-button-primary flex-1 margin-left-2"
        (click)="acceptLicence()"
        [disabled]="pending$ | async"
        >
        Accepter
        @if (pending$ | async) {
          <ion-spinner></ion-spinner>
        }
      </button>
    </div>
  </div>
}
<!-- </ion-content> -->
