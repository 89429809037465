<div class="margin-bottom-2">
    <omedom-directory-search
        (searchChanged)="searchChanged($event)"
    ></omedom-directory-search>
</div>
<div class="card-container">
    @for(pro of prosFiltered; track pro.uid) {
    <omedom-directory-card
        [pro]="pro"
        (detailClicked)="detailClicked($event)"
    ></omedom-directory-card>
    }
</div>
