<div class="propertiesFilter" (click)="presentSmartPopover($event)">
  @if (!canFilter) {
    <div class="smart">
      <i class="omedom-icon-crown"></i>
      <ion-popover
        #smartPopover
        class="omedom-popover"
        [isOpen]="isOpen"
        (didDismiss)="isOpen = false"
        >
        <ng-template>
          <div class="omedom-popover-container">
            <span
              >Pour filtrer vos biens et affiner votre trésorerie,
              changez d'abonnement.</span
              >
              <button
                class="omedom-icon-button-primary"
                (click)="smartPopover.dismiss()"
                routerLink="/tabs/menu/subscription/manage"
                >
                <i class="omedom-icon-crown"></i>
              </button>
            </div>
          </ng-template>
        </ion-popover>
      </div>
    }
    @if (!disabled) {
      <div
        [ngClass]="canFilter && !disabled ? 'unlocked' : 'locked'"
        (click)="openSelect()"
        >
        <i class="uil uil-filter"></i>
        <omedom-select
          #selectApp
          name="bien"
          [hidden]="true"
          (selectExited)="updateFilter()"
          [(ngModel)]="selectedAssets"
          [placeholder]="subjectPlaceholder"
          [options]="(assetOptions$ | async) ?? []"
          [isMultiple]="true"
          [disabled]="!canFilter"
        ></omedom-select>
      </div>
    }

    <div class="property-slider">
      @for (asset of allAssetsOptions; track trackOptionsChanges($index, asset)) {
        <omedom-property-filter-item
          id="{{ asset.id }}"
          [asset]="asset"
          [ngClass]="isUnlock(asset) ? 'unlocked' : 'locked'"
          (click)="propertySelected(asset)"
        ></omedom-property-filter-item>
      }
    </div>
  </div>
