<div
  class="omedom-modal omedom-content-pdf {{ isAndroid ? 'android' : 'web' }}"
  >
  <div class="omedom-modal-header w-100 margin-bottom-2 margin-top-2">
    <h3 class="flex-1">{{ title }}</h3>
    <span>
      <i class="uil uil-times-circle" (click)="close()"></i>
    </span>
  </div>

  <omedom-info
    class="w-100 background-color-light-yellow"
    [information]="infoLabel"
    >
  </omedom-info>
  <hr class="omedom-divider w-100" />

  <omedom-pdf-preview class="w-100" [fileURL]="fileURL"></omedom-pdf-preview>

  <div class="omedom-sticky-button-container w-100 margin-y-2">
    <button
      id="delete-button"
      class="omedom-icon-button-warn-secondary"
      [disabled]="!userRoleState.delete"
      (click)="openDeleteModal()"
      >
      <i class="uil uil-trash-alt"></i>
    </button>

    @if (userRoleState?.export) {
      <a
        class="omedom-button-primary margin-right-2"
        [href]="fileURL"
        [download]="fileURL"
        target="_blank"
        type="button"
        >
        <i class="uil uil-import"></i>
      </a>
    }
    <button
            class="{{
                actionButton
                    ? 'omedom-icon-button-secondary-outline'
                    : 'omedom-button-secondary'
            }} {{ actionButton ? '' : 'flex-1' }}"
      (click)="overrideDocument()"
      >
      {{ actionButton ? '' : 'Terminer' }}
      @if (actionButton) {
        <i class="uil uil-times-circle"></i>
      }
    </button>

    @if (actionButton) {
      <button
        class="omedom-button-{{ actionButton.color }} flex-1"
        (click)="onClickActionButton()"
        >
        <span>
          <i [class]="actionButton.icon"></i>
          {{ actionButton.label }}
        </span>
      </button>
    }
  </div>
</div>
