<div class="content">
  <div class="main-title" style="margin-bottom: 10px">
    <h3>
      <span>Conditions de Partage</span>
    </h3>
    @if (isModal) {
      <i
        class="uil uil-times-circle close"
        (click)="modalController.dismiss()"
      ></i>
    }
  </div>

  <!-- <h3 class="titles">AVEC QUI OMEDOM PARTAGE MES DONNÉES&nbsp;?</h3> -->
  <h3 class="titles">CONDITIONS DE PARTAGE À UN PROFESSIONNEL</h3>
  <div class="text">
    <p>
      Les Conditions de partage qui suivent s'appliquent à un Utilisateur
      ayant reçu, de la part d'un Professionnel, un accès à l'Application
      OMEDOM lui permettant de bénéficier, pendant une durée déterminée,
      de l'ensemble des fonctionnalités de l'Application.
      <!-- Si l'Utilisateur a reçu un code d'accès est en relation avec un professionnel de
      l'immobilier, tel qu'un agent immobilier, un notaire etc. (ci-après
      le « Professionnel »), il peut avoir reçu de ce Professionnel des
      accès à l'application OMEDOM (ci-après « l'Application ») lui
      permettant de bénéficier, pendant une durée déterminée, des
      fonctionnalités de l'Application, aux frais du Professionnel. -->
    </p>
  </div>

  <h3 class="titles">
    DANS QUELLES CONDITIONS MES DONNÉES SONT PARTAGÉES&nbsp;?
  </h3>
  <div class="text">
    <!-- <p>
    OMEDOM ne partage les données de ses Utilisateurs au professionnel
    que dans certains cas de figure (par exemple quand l'Utilisateur
    utilise l'Application grâce au Professionnel) et seulement si
    l'Utilisateur a consenti à ce partage.
  </p>
  <p>
    Autrement dit, si l'Utilisateur ne souhaite pas que le Professionnel
    ait accès à ses données, OMEDOM ne permettra pas un tel accès.
    L'Utilisateur n'est en aucun cas obligé de partager ses données avec
    le Professionnel.
  </p>
  <p>
    Si l'Utilisateur choisit de partager ses données avec le
    Professionnel, il pourra décider de partager les données relatives à
    tous ses biens immobiliers ou seulement à ceux qu'il aura
    sélectionnés.
  </p> -->
  <p>
    OMEDOM partage les données de ses Utilisateurs au Professionnel lui
    ayant fourni l'accès à l'application OMEDOM, seulement si
    l'Utilisateur a consenti à ce partage.
  </p>
  <!-- <p>
  Autrement dit, si l'Utilisateur ne souhaite pas que le
  Professionnel ait accès à ses données, OMEDOM ne permettra pas un
  tel accès. L'Utilisateur n'est en aucun cas obligé de partager ses
  données avec le Professionnel.
</p> -->
<p>
  Si l'Utilisateur choisit de partager ses données avec le
  Professionnel, il pourra décider de partager les données relatives à
  tous ses biens immobiliers ou seulement à ceux qu'il aura
  sélectionnés.
</p>
</div>

<h3 class="titles">QUELLES SONT LES DONNÉES PARTAGÉES&nbsp;?</h3>
<div class="text">
  <p>
    Les données renseignées dans l'application par l'Utilisateur,
    auxquelles le Professionnel aura accès, sont les suivantes :
  </p>
  <ul>
    <li>Nom et prénom de l'Utilisateur</li>
    <li>Adresse et n° de téléphone de l'Utilisateur</li>
    <li>Adresse e-mail de l'Utilisateur</li>
    <!-- <li>
    'Code d'accès' utilisé par l'Utilisateur pour bénéficier de
    l'accès à l'application toutes fonctionnalités débloquées.
  </li> -->

  <li>Quantité totale des biens créés par l'Utilisateur</li>
  <li>
    Informations afférentes à chaque bien partagé par l'Utilisateur
    vers le Professionnel :
    <ul>
      <li>Fiche descriptive du bien</li>
      <li>Trésorerie globale des biens partagés</li>
      <li>Trésorerie par bien (charges et revenus)</li>
      <li>Documents par bien</li>
    </ul>
  </li>
</ul>
</div>

<h3 class="titles">QUELS SONT MES DROITS&nbsp;?</h3>
<div class="text">
  <p>
    L'Utilisateur est invité à consulter les informations relatives à la
    protection de ses données, ainsi qu'aux droits qu'il peut exercer à
    l'égard de ses données, en prenant connaissance de la
    <span class="omedom-link" (click)="showMention('confidentiality')"
      >Politique de confidentialité</span
      >.
    </p>
    <p>
      L'Utilisateur peut adresser toute question relative à ses données au
      Délégué à la Protection des Données d'OMEDOM à l'adresse :
      dpo&#64;omedom.com.
    </p>
  </div>
</div>
