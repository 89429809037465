<div class="header">
  <div class="icon">
    @if (bank && bank.logoUrl) {
      <img
        [src]="bank.logoUrl"
        class="logo"
        />
    } @else {
      <i class="uil uil-euro-circle"></i>
    }

    <ng-template #icon>
      <i class="uil uil-euro-circle"></i>
    </ng-template>
  </div>

  <div class="label">
    <span class="loan-name">{{ loan.name }}</span>

    @if (bank && showBankName) {
      <span class="bank-name"
        >{{ bank.name }}</span
        >
      }
    </div>

    <div class="details">
      @if (loan.interestRate && showInterestRate) {
        <span
          class="interest-rate"
          >
          {{ loan.interestRate | omedomNumber }} %
        </span>
      }

      @if (loan.borrowedCapital && showBorrowedCapital) {
        <span
          class="borrowed-capital"
          >
          {{ loan.borrowedCapital | omedomNumber }} €
        </span>
      }
    </div>
  </div>

  @switch (layout) {
    @case (LoanCardLayout.DEFAULT) {
      <ng-container *ngTemplateOutlet="progressDefault"></ng-container>
    }
    @case (LoanCardLayout.MOBILE) {
      <ng-container *ngTemplateOutlet="progressDefault"></ng-container>
    }
    @case (LoanCardLayout.DASHBOARD) {
      <ng-container *ngTemplateOutlet="progressDashboard"></ng-container>
    }
  }

  @if (showInformations && layout !== LoanCardLayout.DASHBOARD) {
    <div
      class="infos"
      >
      @if (loan.repaidCapital) {
        <div class="info">
          <span class="title">Montant remboursé</span>
          <span class="value" id="repaid"
            >{{ loan.repaidCapital | omedomNumber }} €</span
            >
          </div>
        }
        @if (loan.remainingCapital) {
          <div class="info">
            <span class="title">Capital restant dû</span>
            <span class="value" id="remaining"
              >{{ loan.remainingCapital | omedomNumber }} €</span
              >
            </div>
          }
          @if (loan.nextPaymentAmount && loan.nextPaymentDate) {
            <div class="info">
              <span class="title">Prochain prélèvement</span>
              <div class="container">
                <span class="value" id="next-payment-amount"
                  >{{ loan.nextPaymentAmount | omedomNumber }} €</span
                  >
                  <span>le</span>
                  <span class="value" id="next-payment-date"
                    >{{ loan.nextPaymentDate | date:'dd/MM' }}</span
                    >
                  </div>
                </div>
              }
            </div>
          }

          @if (showFooter && layout !== LoanCardLayout.MOBILE) {
            <div class="actions">
              <button class="omedom-button-secondary" (click)="onEvolutionClick.emit()">
                <i class="uil uil-chart-line"></i>
              </button>
              <button class="omedom-button-primary" (click)="onSeeMoreClick.emit()">
                Voir plus
              </button>
            </div>
          }

          <ng-template #progressDefault>
            @if (showProgress) {
              <div class="progress">
                <div class="progress-bar">
                  <div class="progress-value" [style.width]="refunded + '%'"></div>
                </div>
                <div class="duration">
                  <span class="opening"
                    >{{ loan.openingDate | date:'dd/MM/yyyy' }}</span
                    >
                    <span class="maturity"
                      >{{ loan.maturityDate | date:'dd/MM/yyyy' }}</span
                      >
                    </div>
                  </div>
                }
              </ng-template>

              <ng-template #progressDashboard>
                @if (showProgress) {
                  <div class="progress">
                    <div class="infos-list">
                      @if (loan.repaidCapital) {
                        <div class="info">
                          <span class="title">Montant remboursé :</span>
                          <span class="value" id="repaid"
                            >{{ loan.repaidCapital | omedomNumber }} €</span
                            >
                          </div>
                        }
                        @if (loan.remainingCapital) {
                          <div class="info">
                            <span class="title">Capital restant dû :</span>
                            <span class="value" id="remaining"
                              >{{ loan.remainingCapital | omedomNumber }} €</span
                              >
                            </div>
                          }
                        </div>
                        <div class="progress-bar">
                          <div
                            class="progress-value layout-dashboard"
                            [style.width]="refunded + '%'"
                          ></div>
                          <div class="progress-remaining"></div>
                        </div>
                        <div class="infos-list" style="justify-content: center">
                          @if (loan.nextPaymentAmount && loan.nextPaymentDate) {
                            <div
                              class="info"
                              >
                              <span class="title">Prochain prélèvement :</span>
                              <div class="container">
                                <span class="value" id="next-payment-amount"
                                  >{{ loan.nextPaymentAmount | omedomNumber }} €</span
                                  >
                                  <span>le</span>
                                  <span class="value" id="next-payment-date"
                                    >{{ loan.nextPaymentDate | date:'dd/MM' }}</span
                                    >
                                  </div>
                                </div>
                              }
                            </div>
                          </div>
                        }
                      </ng-template>
