<div class="omedom-modal">
    <div class="title">
        <h3 class="width-auto">
            <span>{{ selectTitle }}</span>
        </h3>
        @if(selectionNumberLimit) {
        <div class="vertical-center">
            Sélection maximale:
            <span class="bold margin-left-1">{{ selectionNumberLimit }}</span>
        </div>
        }
        <i class="uil uil-times-circle" (click)="dismissSelect()"></i>
    </div>
    @if(isLimitReached) {

    <div class="error-message">Limite de sélection atteinte</div>

    } @if(optGroupOptions){ @for(optGroup of optGroupOptions; track
    optGroup.label) {
    <div class="bold margin-bottom-2 margin-left-1">{{ optGroup.label }} :</div>
    @for (option of optGroup.options; track option.id) {
    <omedom-select-option
        [isOpen]="true"
        [option]="option"
        (optionClicked)="optionClicked(option)"
    ></omedom-select-option>
    } } }@else {

    <ng-container
        *ngTemplateOutlet="
            detailedOptions ? detailedSelectOption : selectOption
        "
    ></ng-container>
    }

    <ng-template #selectOption>
        @for (option of options; track option.id) {
        <omedom-select-option
            [isOpen]="true"
            [option]="option"
            (optionClicked)="optionClicked(option)"
        ></omedom-select-option>
        }
    </ng-template>

    <ng-template #detailedSelectOption>
        @for (option of options; track option.id) {
        <omedom-detailed-option
            [isOpen]="true"
            [option]="option"
            (optionClicked)="optionClicked(option)"
        ></omedom-detailed-option>
        }
    </ng-template>
</div>
