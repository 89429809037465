<div class="content">
  <h3 class="main-title">
    Politique de confidentialité
    @if (isModal) {
      <i
        class="uil uil-times-circle close"
        (click)="modalController.dismiss()"
      ></i>
    }
  </h3>
  <div class="text">
    <p>
      La présente politique de protection des données (ci-après «
      <b>&nbsp;la Politique&nbsp;</b>») définit les conditions dans
      lesquelles la société par actions simplifiée OMEDOM, immatriculée au
      registre du commerce et des sociétés d'Albi sous le numéro 895 124
      949 et dont le siège social est situé à 72 bis, Rue du Commandant
      Blanché 81000 ALBI (ci-après «<b>&nbsp;OMEDOM&nbsp;</b>») traite les
      données à caractère personnel des personnes bénéficiant ou
      souhaitant bénéficier des services proposés sur son application web
      et mobile (ci-après «<b>&nbsp;la Solution&nbsp;</b>»). Avant
      d'accéder à la Solution et à ses services, l'Utilisateur est invité
      à prendre connaissance des dispositions ci-dessous.
    </p>
    <p>
      Avant d'accéder à la Solution et à ses services, l'Utilisateur est
      invité à prendre connaissance des dispositions ci-dessous.
    </p>
  </div>

  <h3 class="titles">1. Définitions</h3>
  <div class="text">
    <p>
      La présente Politique a pour objet de définir la manière dont les
      données sont recueillies et traitées par le Responsable de
      Traitement. Les termes indiqués ci-dessous ont la définition
      suivante :
    </p>
    <p>
      «<b>&nbsp;Client&nbsp;</b>» désigne la personne physique souscrivant
      un contrat d'abonnement et/ou bénéficiant ou souhaitant bénéficier
      des services proposés sur la Solution, le terme Client incluant le
      Client potentiel ;
    </p>
    <p>
      «<b>&nbsp;Données à caractère personnel&nbsp;</b>» ou
      «<b>&nbsp;Données&nbsp;</b>» désignent toute information permettant
      d'identifier directement ou indirectement une personne physique ;
    </p>
    <p>
      «<b>&nbsp;Délégué à la Protection des Données&nbsp;</b>» ou
      «<b>&nbsp;DPO&nbsp;</b>» désignent la personne en charge de
      conseiller et de contrôler le Responsable de Traitement en matière
      de protection des Données à caractère personnel. En l'espèce, le DPO
      de OMEDOM est joignable à l'adresse
      <a href="mailto: dpo@omedom.com">dpo&#64;omedom.com</a> ;
    </p>
    <p>
      «<b>&nbsp;Responsable de Traitement&nbsp;</b>» désigne la personne
      physique ou morale qui détermine les finalités et les moyens des
      traitements des Données à caractère personnel, en l'occurrence
      OMEDOM, société par actions simplifiée immatriculée au registre du
      commerce et des sociétés d'Albi sous le numéro 895 124 949 et dont
      le siège social est situé à 72 bis, Rue du Commandant Blanché 81000
      ALBI.
    </p>
    <p>
      «<b>&nbsp;Sous-traitant&nbsp;</b>» désigne la personne physique ou
      morale en charge de traiter des Données à caractère personnel au nom
      et pour le compte du Responsable de Traitement dans le cadre d'un
      service ou d'une prestation ;
    </p>
    <p>
      «<b>&nbsp;Traitement&nbsp;</b>» désigne toute opération portant sur
      des Données à caractère personnel, quel que soit le procédé utilisé,
      informatisé ou non, tels que la collecte, l'enregistrement,
      l'organisation, l'adaptation, la modification, l'extraction, la
      consultation, la communication, l'utilisation, la diffusion ou le
      rapprochement de Données ;
    </p>
    <p>
      «<b>&nbsp;Utilisateur&nbsp;</b>» désigne la personne physique
      naviguant sur la Solution.
    </p>
  </div>

  <h3 class="titles">2. Personnes concernées</h3>
  <div class="text">
    <p>
      La présente Politique s'adresse aux Utilisateurs et aux Clients de
      la Solution personnes physiques, aux employés et dirigeants des
      Clients personnes morales.
    </p>
  </div>
  <h3 class="titles">
    3. Finalités et bases légales des traitements de données
  </h3>
  <div class="text">
    <p>
      Les Données à caractère personnel des Utilisateurs et/ou des Clients
      sont collectées et traitées par le Responsable de Traitement sur la
      base de fondements légaux et dans la mesure elles sont strictement
      nécessaires à la réalisation des finalités ci-dessous. Ces finalités
      sont déterminées, explicites et légitimes.
    </p>
    <p>
      La majorité des Traitements mis en œuvre par OMEDOM sont fondés sur
      l'exécution du contrat entre OMEDOM et l'Utilisateur et/ou le
      Client. Les finalités suivantes sont donc basées sur ce fondement ou
      l'exécution de mesures précontractuelles :
    </p>
    <ul>
      <li>
        Traitement des demandes d'informations adressées par
        l'Utilisateur et/ou le Client par le biais du Menu, "Aide et
        Contact";
      </li>
      <li>Création et gestion du compte Utilisateur;</li>
      <li>Ajout d'un bien dans le compte de l'Utilisateur;</li>
      <li>
        Partage du bien avec un tiers (qui inclut le partage des : nom,
        prénom et adresse électronique de l'Utilisateur/Client);
      </li>
      <li>
        Génération de documents tels que les factures, les lettres de
        relance et les quittances de loyer;
      </li>
      <li>Paramétrage et suivi du budget Utilisateur sur chaque bien;</li>
      <li>Suivi de la trésorerie sur l'ensemble du patrimoine;</li>
      <li>Suivi des épargnes/placements;</li>
      <li>Suivi des crédits en cours;</li>
      <li>
        Génération de données de valorisation et de diagnostic de
        performance énergétique;
      </li>
      <li>Souscription à un abonnement à la Solution;</li>
      <li>Traitement de Données bancaires;</li>
      <li>Administration de la Solution;</li>
      <li>Lutte contre la fraude;</li>
      <li>Prévention d'éventuels contentieux.</li>
    </ul>
    <p>
      En l'absence de certaines Données impératives (nom, prénom, code
      postal, adresse mail et mot de passe), OMEDOM ne sera pas en mesure
      de traiter la demande de l'Utilisateur ou du Client, de créer le
      compte et de mettre en place l'abonnement demandé.
    </p>
    <p>
      Les Données d'ordre économique et financier (revenus, situation
      financière, Données bancaires) traitées par OMEDOM le sont à des
      fins exclusives d'utilisation de la Solution.
    </p>
    <p>
      Les Traitements réalisés sur la base du consentement de
      l'Utilisateur et/ou du Client sont notamment les suivants :
    </p>
    <ul>
      <li>
        Personnalisation de la Solution via le traçage et le suivi de la
        navigation de l'Utilisateur et/ou du Client (cookies) à des fins
        d’analyses et d’améliorations de l’expérience utilisateur et/ou
        du Client;
      </li>
      <li>Envoi de newsletters aux Clients particuliers;</li>
      <li>Envoi de notifications à l'Utilisateur et/ou au Client;</li>
      <li>Envoi de communications à l'Utilisateur et/ou au Client.</li>
    </ul>
    <p>
      L'Utilisateur et/ou le Client ont le droit de retirer leur
      consentement à tout moment, sans porter atteinte à la licéité du
      Traitement effectué avant le retrait de celui-ci.
    </p>
    <p>
      Enfin, OMEDOM peut traiter certaines Données aux fins des intérêts
      légitimes qu'elle poursuit dans le cadre d'opérations marketing et
      statistiques relatives à l'utilisation de la Solution et de sa
      prospection commerciale :
    </p>
    <ul>
      <li>
        Évaluation et amélioration des services et de l'expérience
        Utilisateur;
      </li>
      <li>Enquêtes de satisfaction;</li>
      <li>Envoi de newsletters aux Clients professionnels.</li>
    </ul>
    <p>
      L'Utilisateur et/ou le Client ont la possibilité de se désinscrire,
      à tout moment, de toute communication électronique en cliquant sur
      le bouton «&nbsp;se désinscrire&nbsp;» accessible en bas de chaque
      communication ou en décochant la/les notification(s) dans la
      Solution pour toute(s) notification(s) reçue(s) par courrier
      électronique.
    </p>
  </div>

  <h3 class="titles">4. Destinataires des données</h3>
  <div class="text">
    <p>
      Les destinataires des Données collectées et traitées par OMEDOM sont
      :
    </p>
    <ul>
      <li>
        Les membres habilités de son personnel en raison de leurs
        fonctions;
      </li>
      <li>
        Les personnes éventuellement en charge de la maintenance de la
        Solution, qu'elles soient internes ou externes à OMEDOM;
      </li>
      <li>Les fournisseurs de solutions de paiement des abonnements;</li>
      <li>Les fournisseurs de solutions d’agrégation bancaires;</li>
      <li>
        Les fournisseurs de solutions d’analyse de données pour la
        valorisation et le diagnostic de performance énergétique;
      </li>
      <li>
        Les fournisseurs de solutions de lutte contre les risques liés
        aux paiements;
      </li>
      <li>Les fournisseurs de solutions d'analyse de Données.</li>
    </ul>

    <p>
      Enfin, le Responsable de Traitement est susceptible de communiquer
      les Données des Utilisateurs et/ou des Clients à des destinataires
      légitimes pour des motifs exigés par la loi..
    </p>
  </div>

  <h3 class="titles">5. Droit des utilisateurs et des clients</h3>
  <div class="text">
    <p>
      Les droits des Utilisateurs et/ou des Clients sur leurs Données sont
      les suivants :
    </p>
    <ul>
      <li><b>Droit d'accès aux Données</b></li>
      <p>
        L'Utilisateur et/ou le Client peuvent demander au Responsable de
        Traitement la confirmation que leurs Données à caractère
        personnel sont ou ne sont pas traitées.
      </p>
      <p>
        Si le Responsable de Traitement traite effectivement des
        Données, l'Utilisateur et/ou le Client peuvent en contrôler
        l'exactitude en sollicitant une copie, lisible dans un format
        compréhensible, de toutes informations que le Responsable de
        Traitement détient les concernant.
      </p>
      <li><b>Droit de rectification des Données</b></li>
      <p>
        L'Utilisateur et/ou le Client peuvent demander la modification
        de leurs Données à caractère personnel lorsque celles-ci sont
        erronées ou incomplètes.
      </p>
      <li><b>Droit d'opposition au Traitement</b></li>
      <p>
        L'Utilisateur et/ou le Client peuvent s'opposer, pour des motifs
        légitimes, à ce que leurs Données à caractère personnel soient
        utilisées pour des finalités précises.
      </p>
      <li><b>Droit de retrait du consentement</b></li>
      <p>
        L'Utilisateur et/ou le Client peuvent retirer, à tout moment,
        leur consentement lorsque le Traitement de leurs Données à
        caractère personnel est fondé sur celui-ci.
      </p>
      <li><b>Droit à la limitation du Traitement</b></li>
      <p>
        L'Utilisateur et/ou le Client peuvent solliciter que le
        Traitement de leurs Données à caractère personnel soit bloqué
        pendant un certain temps, notamment le temps d'examiner une
        demande d'exercice des droits.
      </p>
      <li><b>Droit à l'effacement des Données</b></li>
      <p>
        L'Utilisateur et/ou le Client peuvent demander l'effacement des
        Données à caractère personnel que le Responsable de Traitement
        détient sur eux, sous réserve que des motifs impérieux ou légaux
        ne permettent à OMEDOM de les conserver.
      </p>
      <li>
        <b
          >Droit de donner des directives sur le sort des Données
          après le décès</b
          >
        </li>
        <p>
          L'Utilisateur et/ou le Client ont la possibilité d'informer le
          Responsable de Traitement sur les modalités d'utilisation de ses
          Données après son décès.
        </p>
        <li><b>Droit à la portabilité des Données</b></li>
        <p>
          L'Utilisateur et/ou le Client peuvent solliciter la récupération
          de certaines de leurs Données pour leur propre usage ou dans le
          but de les communiquer à un autre organisme.
        </p>
      </ul>

      <p class="framed">
        Pour toute demande d'exercice de droits, l'Utilisateur et/ou le
        Client doivent envoyer leurs demandes à l'adresse
        <a href="mailto: dpo@omedom.com">dpo&#64;omedom.com</a>.
      </p>
      <p>
        OMEDOM pourra demander la communication de la copie d'une pièce
        justificative d'identité si nécessaire. OMEDOM s'engage à répondre
        dans les meilleurs délais, et en tout état de cause dans un délai
        d'un (1) mois à compter de la réception de la demande complète. Ce
        délai peut néanmoins être prorogé de deux (2) mois compte tenu de la
        complexité et du nombre de demandes.
      </p>
      <p>
        En l'absence de réponse satisfaisante du Responsable de Traitement,
        l'Utilisateur et/ou le Client sont en droit d'introduire une
        réclamation auprès de la CNIL, 3 place de Fontenoy. TSA&nbsp;80715.
        75334 Paris Cedex&nbsp;07.
      </p>
    </div>

    <h3 class="titles">6. Durée de conservation des données</h3>
    <div class="text">
      <p>
        OMEDOM s'engage à respecter les durées de conservation imposées par
        la réglementation en vigueur.
      </p>
      <p>
        Les Données traitées dans le cadre d'un contrat d'abonnement à la
        Solution sont conservées pendant toute la durée de ce dernier et
        jusqu'à 3 (trois) ans après la résiliation de celui-ci.
      </p>
      <p>
        Les éléments contractuels sont conservés pendant 5 (cinq) ans à
        l'issue du contrat à titre probatoire et les Données relatives à la
        facturation peuvent être conservées 10 (dix) ans en vertu d'une
        obligation comptable légale.
      </p>
      <p>
        Les documents fiscaux peuvent être conservés jusqu'à 6 (six) ans à
        compter de leur émission.
      </p>
      <p>
        Les Données relatives à la gestion des biens peuvent être conservées
        jusqu'à 5 (cinq) ans après leur émission.
      </p>
    </div>

    <h3 class="titles">7. Traitement des données hors de l'union européenne</h3>
    <div class="text">
      <p>
        Les Données à caractère personnel traitées sont conservées en
        France.
      </p>
      <p>
        Il est toutefois possible que, dans le cadre de certaines missions,
        certaines Données soient traitées par des Sous-traitants situés hors
        de l'Union européenne. Soucieuse de protéger la vie privée et les
        Données à caractère personnel des Utilisateurs et/ou des Clients,
        OMEDOM fait ses meilleurs efforts pour sélectionner des partenaires
        offrant des garanties de sécurité et de confidentialité et mettre en
        place des relations contractuelles correspondant aux standards
        réglementaires, notamment au niveau européen.
      </p>
    </div>

    <h3 class="titles">8. Modifications de la présente politique</h3>
    <div class="text">
      <p>
        Le Responsable de Traitement se réserve le droit de modifier la
        présente Politique de protection des Données à caractère personnel.
        Dans ce cas, une version mise à jour sera publiée sur le Site et
        dans la Solution et l'Utilisateur/Client en sera informé par tout
        moyen.
      </p>
    </div>

    <h3 class="titles">9. Coordonnées</h3>
    <div class="text">
      <p>
        Pour toute information complémentaire ou toute réclamation relative
        à l'utilisation de ses Données, l'Utilisateur/le Client peut
        contacter le DPO d'OMEDOM à l'adresse :
        <a href="mailto: dpo@omedom.com">dpo&#64;omedom.com</a>.
      </p>
    </div>
  </div>
