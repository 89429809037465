@if (!isOmedomIcon) {
  <i class="uil uil-{{ icon }}"></i>
}
<!-- import svg -->
@if (isOmedomIcon) {
  <div class="container-icon">
    @if (isOmedomIcon) {
      <div
        class="icon"
        [style.mask]="iconMask"
        [style.-webkit-mask]="iconMask"
      ></div>
    }
  </div>
}
<span>{{ label }}</span>
