<div class="container"
  [style.gap]="gap + 'px'">
  @if (label && labelPosition === 'top') {
    <ng-container *ngTemplateOutlet="title"></ng-container>
  }

  <div class="omedom-graph-container">
    <canvas baseChart
      [id]="id"
      [data]="doughnutChartData"
      [options]="doughnutChartOptions"
    type="doughnut"></canvas>

    <div class="omedom-graph-total">
      <span class="omedom-graph-total-title">Total</span>
      <span class="omedom-graph-total-value">{{ total | number : '0.2-2' }}&nbsp;€</span>
    </div>
  </div>
</div>

@if (showLegend) {
  <div class="legend-container"
    >
    @if (label && labelPosition === 'right') {
      <ng-container *ngTemplateOutlet="title"></ng-container>
    }
    <!-- Legend -->
    <omedom-chart-legend [legends]="legends"></omedom-chart-legend>
  </div>
}

<ng-template #title>
  <h3 class="omedom-graph-title"
    [style.justify-content]="labelPosition === 'top' ? 'center' : 'flex-start'">
    @if (icon) {
      <i class="uil uil-{{ icon }}"
      ></i>
    }
    {{ label }}
  </h3>
</ng-template>
