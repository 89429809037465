@if (story) {
  <div class="card" [class.readed]="story.isReaded">
    <div class="icon">
      <ng-container
            *ngTemplateOutlet="
                story.propertyImg ? propertyImg : defautPropertyImg
            "
      ></ng-container>
      <ng-template #propertyImg>
        <img class="property-img" [src]="story.propertyImg" />
      </ng-template>
      <ng-template #defautPropertyImg>
        <i [class]="icon"></i>
      </ng-template>
    </div>
    <div class="footer">
      <i [class]="story.categoryInfo.icon"></i>
      <span
        class="price"
        [class.color-red]="story.isCharge"
        [class.color-green]="!story.isCharge"
        >
        {{ story.isCharge ? '-' : '+'
        }}{{
        (story.amount | number : '0.2-2') ?? 0 | omedomNumber
        }}&nbsp;{{ story.currency ?? '€' }}
      </span>
    </div>
  </div>
}
