<div class="chat-card">
    <div
        class="chat-card-content"
        (click)="clientSelected.emit(client)"
        [class.active]="isSelected"
    >
        <div class="chat-card-icon">
            @if ( user.avatar ) {
            <img
                src="{{ user.avatar }}"
                alt="avatar"
                class="chat-card-avatar"
            />
            } @else {
            <i class="uil uil-user-circle"></i>}
        </div>
        <div class="chat-card-content-info">
            <h3 class="chat-card-title">
                {{ displayName }}
            </h3>
        </div>
    </div>
</div>
