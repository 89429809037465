@if (tenant) {
  <div class="w-100">
    <!-- affiche le locataire seulement s'il y en a plusieurs -->
    <h2>
      <i class="uil uil-users-alt uil-icon"></i>
      {{ tenant?.firstname }} {{ tenant.lastname }}
    </h2>
  </div>
}
<div
    class="{{
        isAndroid ? 'android' : 'web'
    }} previsualisation flex-1 w-100 margin-top-2"
  >
  @if (isAndroid && (sanitized$ | async)) {
    <div class="mobile actions-container">
      <button
        class="omedom-button-secondary"
        (click)="displayPDF(fileURL)"
        >
        <i class="uil uil-eye"></i>
        visualiser
      </button>
    </div>
  } @else {
    @if (sanitized$ | async) {
      <div
        class="viewer-container margin-bottom-1"
        >
        <iframe
          class="viewer-pdf"
          [src]="sanitizeURL"
          frameborder="0"
          allowAutoPlay="true"
          >
        </iframe>
      </div>
    }
  }

</div>
