<i
  [ngClass]="icon"
  class="content-info-icon {{ iconColor }}"
  [id]="popoverId"
></i>
<ion-popover class="omedom-popover" [trigger]="popoverId">
  <ng-template>
    <div></div>

    <div
      class="omedom-popover-container"
      [ngClass]="{ 'flex-column': iconTop }"
      >
      @if (withIcon) {
        <i [ngClass]="icon"></i>
      }
      @if (contentType === 'string') {
        <div
          class="omedom-popover-content"
          >
          {{ content }}
        </div>
      }
      @if (contentType === 'object' && content) {
        <ul
          class="omedom-popover-content list-without-bullet-point"
          [ngClass]="{ 'omedom-popover-ul': !withMargin }"
          >
          @for (paragaraph of content; track paragaraph) {
            <li class="margin-top-1">
              {{ paragaraph }}<br />
            </li>
          }
        </ul>
      }
    </div>
  </ng-template>
</ion-popover>
