<div class="omedom-modal">
  <div class="omedom-modal-header">
    <div class="omedom-modal-header-title">
      @if (image) {
        <img
          class="form-avatar margin-top-1"
          [src]="image"
          />
      } @else {
        <div class="header-icon margin-top-1">
          <i [class]="avatar"></i>
        </div>
      }
      @if (title) {
        <h3>{{ title }}</h3>
      } @else {
        <h3>Attention</h3>
      }
    </div>
    <i class="uil uil-times-circle" (click)="dismiss()"></i>
  </div>
  <div class="omedom-modal-content">
    <omedom-info
      information="Attention, vous allez perdre les informations entrées."
      >
    </omedom-info>
    <span class="confirmation-text">
      Êtes-vous sûr.e de vouloir quitter cette page ?
    </span>
  </div>
  <div class="omedom-modal-buttons">
    <button class="omedom-button-secondary" (click)="dismiss()">
      Non, continuer
    </button>
    <button class="omedom-button-warn" (click)="back()">
      Oui, quitter
    </button>
  </div>
</div>


