<div class="modal-content">
  <div class="content">
    <div class="header">
      <span> Informations du locataire </span>
      <i class="uil uil-times-circle" (click)="dismiss()"> </i>
    </div>
    <form #form="ngForm" class="w-100 flex-1">
      <omedom-input
        [required]="true"
        [(ngModel)]="tenant.lastname"
        name="lastName"
        placeholder="Nom"
        icon="uil uil-user-circle"
        label="Nom"
        >
      </omedom-input>

      <omedom-input
        [required]="true"
        [(ngModel)]="tenant.firstname"
        name="firstName"
        placeholder="Prénom"
        icon="uil uil-user-circle"
        label="Prénom"
        class="margin-top-2"
        >
      </omedom-input>

      <omedom-input
        type="email"
        [(ngModel)]="tenant.email"
        name="email"
        [pattern]="emailRegex"
        placeholder="E-mail"
        icon="uil uil-envelope"
        label="E-mail"
        class="margin-top-2"
        >
      </omedom-input>

      <omedom-input
        type="tel"
        [(ngModel)]="tenant.phone"
        name="phone"
        [pattern]="phoneRegex"
        placeholder="+33"
        icon="uil uil-phone-volume"
        label="Téléphone"
        class="margin-top-2"
        >
      </omedom-input>

      <div
        class="form-collapse"
        (click)="displayGarant.next(!displayGarant.value)"
        >
        <span>
          <i class="uil-book-reader"> </i>
          Garant
        </span>
        <i
          class="uil"
          [class.uil-angle-down]="!displayGarant.value"
          [class.uil-angle-up]="displayGarant.value"
          class="arrow-icon"
          >
        </i>
      </div>
      @if ((displayGarant | async) && tenant.warrantor) {
        <div
          class="sub-form"
          >
          <omedom-input
            name="warrantorLastName"
            placeholder="Nom"
            icon="uil uil-user-circle"
            label="Nom"
            [(ngModel)]="tenant.warrantor.lastname"
            >
          </omedom-input>
          <omedom-input
            name="warrantorFirstName"
            placeholder="Prénom"
            icon="uil uil-user-circle"
            label="Prénom"
            [(ngModel)]="tenant.warrantor.firstname"
            class="margin-top-2"
            >
          </omedom-input>
          <omedom-input
            type="email"
            name="warrantorEmail"
            [pattern]="emailRegex"
            placeholder="Mail"
            icon="uil uil-envelope"
            label="Mail"
            [(ngModel)]="tenant.warrantor.email"
            class="margin-top-2"
            >
          </omedom-input>
          <omedom-input
            type="tel"
            name="warrantorPhone"
            placeholder="+33"
            [pattern]="phoneRegex"
            icon="uil uil-phone-volume"
            label="Téléphone"
            [(ngModel)]="tenant.warrantor.phone"
            class="margin-top-2"
            >
          </omedom-input>
        </div>
      }

      <div
        class="form-collapse"
        (click)="displayaid.next(!displayaid.value)"
        >
        <span>
          <i class="uil-money-withdraw"> </i>
          Aides
        </span>
        <i
          class="uil"
          [class.uil-angle-down]="!displayaid.value"
          [class.uil-angle-up]="displayaid.value"
          class="arrow-icon"
          >
        </i>
      </div>
      @if (displayaid | async) {
        <div class="sub-form">
          <form #aidForm="ngForm">
            <omedom-select
              [(ngModel)]="selectedAid"
              name="aidIncome"
              [placeholder]="aidPlaceholder"
              [options]="aidOptions || []"
              >
            </omedom-select>
          </form>
          <!-- <div class="text-center margin-top-2 margin-bottom-2" style="text-align: center;">Ou</div>
          <button class="omedom-button-primary w-100"
            (click)="addAid()">
            <i class="uil uil-plus"></i>
            <span>Ajouter une aide</span>
          </button> -->
        </div>
      }
    </form>
    <div class="omedom-modal-buttons">
      <button class="omedom-button-secondary flex-1" (click)="dismiss()">
        Annuler
      </button>
      <button
        class="omedom-button-green flex-1"
        (click)="saveTenant()"
        [disabled]="form.invalid"
        >
        <i class="uil uil-save"></i>
        <span>Enregistrer</span>
      </button>
    </div>
  </div>
</div>
