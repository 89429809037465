@if(reviewForm) {
<form [formGroup]="reviewForm" (ngSubmit)="onSubmit()">
    <div>Donnez une note :</div>
    <omedom-star-rate
        [rate]="rate"
        (rateChange)="rateChange($event)"
    ></omedom-star-rate>

    <omedom-checkbox formControlName="isAnonymous"
        >Masquer mon identité</omedom-checkbox
    >

    <omedom-input
        formControlName="comment"
        name="pro-comment"
        type="text"
        class="textarea omedom-pro margin-top-2"
        placeholder="Commentaire..."
        autocomplete="off"
        [debounce]="300"
        label="Ajoutez un commentaire:"
    ></omedom-input>

    <div class="omedom-space-between w-100 margin-top-2">
        <button
            class="omedom-button-secondary margin-right-1 flex-1"
            (click)="cancel()"
        >
            Annuler
        </button>
        <button
            class="omedom-button-green flex-1"
            type="submit"
            [disabled]="!isFormValid()"
        >
            <i class="uil uil-save"></i>
            Enregistrer
        </button>
    </div>
</form>
}
