import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class RoutingService {
    /**
     * @description Title of the route
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 26/09/2023
     * @memberof RoutingService
     */
    public title = new BehaviorSubject<string>('');

    /**
     * @description Icon of the route
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 26/09/2023
     * @memberof RoutingService
     */
    public icon = new BehaviorSubject<string>('');

    /**
     * @description True if the icon is an omedom icon, false otherwise
     * @author Killian Brisset
     * @date 09/08/2024
     * @memberof RoutingService
     */
    public isOmedomIcon = new BehaviorSubject<boolean>(false);

    /**
     * @description Enable back button in toolbar
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 26/09/2023
     * @memberof RoutingService
     */
    public enableBack = new BehaviorSubject<boolean>(false);

    /**
     * @description Font size of the title
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 04/09/2024
     * @memberof RoutingService
     */
    public titleFontSize = new BehaviorSubject<number>(36);

    constructor() {}
}
