<table class="table">
    <thead>
        <ng-container
            *ngTemplateOutlet="
                !collapsable || expanded ? headerColumns : headerCollapsed
            "
        ></ng-container>

        <ng-template #headerColumns>
            <tr class="row">
                @for (column of columns; track column.label) {
                <th
                    (click)="
                        column.sortable ? sortData(column.key, true) : null
                    "
                    [style.cursor]="column.sortable ? 'pointer' : 'default'"
                    class="column header"
                >
                    <div class="container">
                        {{ column.label }}
                        @if (sort?.key === column.key) {
                        <i
                            class="sort-icon uil uil-arrow-{{
                                sort?.direction === 'asc' ? 'up' : 'down'
                            }}"
                        ></i>
                        } @if (sort?.key !== column.key && column.sortable) {
                        <i class="uil uil-direction sort-available"></i>
                        }
                    </div>
                </th>
                }
                <ng-container *ngTemplateOutlet="actionsHeader"></ng-container>
            </tr>
        </ng-template>

        <ng-template #headerCollapsed>
            <tr class="row" (click)="expanded = !expanded">
                <th class="column header collapsable">
                    <ng-content select="[omedom-collapsed-header]"></ng-content>
                </th>
                <ng-container *ngTemplateOutlet="actionsHeader"></ng-container>
            </tr>
        </ng-template>
    </thead>

    @if (!collapsable || expanded) {
    <tbody>
        <ng-content select="[omedom-sticky-data]"></ng-content>
        @if (paginatedData?.length ?? 0 > 0) { @for (row of paginatedData; track
        trackByItems($index, row)) {
        <tr
            [class.hoverable]="rowHoverable && !disableCondition(row)"
            [class.disabled]="disableCondition(row)"
            (click)="disableCondition(row) ? null : onAction(row)"
            class="row"
        >
            @for (column of columns; track column.label) {
            <td
                [style.width]="column.width ?? null"
                class="column {{
                    column.validator && column.validator(row)
                        ? 'color-' + column.validator(row)
                        : null
                }}"
            >
                @switch (column.type) { @case (primitive.picture) { @if
                (isIcon(column.transformer ? column.transformer(row) :
                row[column.key])) {
                <i
                    class="uil uil-{{
                        column.transformer
                            ? column.transformer(row)
                            : row[column.key]
                    }}"
                ></i>
                } @else {
                <img [src]="row[column.key]" class="avatar" />
                }
                <ng-template #avatar>
                    <img [src]="row[column.key]" class="avatar" />
                </ng-template>
                } @default {
                {{
                    column.transformer
                        ? column.transformer(row)
                        : row[column.key]
                }}
                } }
            </td>
            }
            <td class="column actions">
                @for (action of actions; track $index) {
                <button
                    [disabled]="
                        action.validator ? !action.validator(row) : false
                    "
                    class="omedom{{ action.title ? null : '-icon' }}-button-{{
                        action.appearance
                    }}{{ action.minimal || action.title ? null : '-outline' }}"
                    [title]="action.placeholder ?? ''"
                    (click)="rowHoverable ? null : action.callback(row)"
                >
                    @if (action.icon) {
                    <i class="uil uil-{{ action.icon }}"></i>
                    }
                    {{ action.title }}
                </button>
                }
            </td>
        </tr>
        } } @else {
        <tr class="row">
            <td
                class="column empty"
                [attr.colspan]="(columns?.length ?? 1) + 1"
            >
                {{ emptyMessage ?? 'Aucune donnée à afficher' }}
            </td>
        </tr>
        }
    </tbody>

    } @if (!collapsable || expanded) {
    <tfoot>
        <tr class="row">
            <td
                class="column pagination"
                [attr.colspan]="(columns?.length ?? 1) + 1"
            >
                <div class="pagination-container">
                    <button
                        class="omedom-icon-button-secondary"
                        [disabled]="pagination.page === 1"
                        (click)="changePage(1)"
                    >
                        <i class="uil uil-angle-double-left"></i>
                    </button>
                    <button
                        class="omedom-icon-button-secondary"
                        [disabled]="pagination.page === 1"
                        (click)="changePage(pagination.page - 1)"
                    >
                        <i class="uil uil-angle-left"></i>
                    </button>
                    <!-- Select page -->
                    <select
                        class="omedom-select"
                        [(ngModel)]="pagination.page"
                        (ngModelChange)="changePage($event)"
                    >
                        @for (page of getNumberOfPages(); track $index) {
                        <option [value]="page">
                            {{ page }}
                        </option>
                        }
                    </select>
                    <button
                        class="omedom-icon-button-secondary"
                        [disabled]="
                            pagination.page === pagination.numberOfPages
                        "
                        (click)="changePage(pagination.page + 1)"
                    >
                        <i class="uil uil-angle-right"></i>
                    </button>
                    <button
                        class="omedom-icon-button-secondary"
                        [disabled]="
                            pagination.page === pagination.numberOfPages
                        "
                        (click)="changePage(pagination.numberOfPages)"
                    >
                        <i class="uil uil-angle-double-right"></i>
                    </button>
                </div>
            </td>
        </tr>
    </tfoot>
    }
</table>

<!-- If the data array is empty -->
<ng-template #empty>
    <tr class="row">
        <td class="column empty" [attr.colspan]="(columns?.length ?? 1) + 1">
            {{ emptyMessage ?? 'Aucune donnée à afficher' }}
        </td>
    </tr></ng-template
>

<!-- Actions header -->
<ng-template #actionsHeader>
    <th class="column header" style="text-align: right">
        @if (collapsable) {
        <button
            class="omedom-icon-button-secondary"
            [title]="expanded ? 'Réduire' : 'Agrandir'"
            (click)="expanded = !expanded"
        >
            <i class="uil uil-{{ expanded ? 'angle-up' : 'angle-down' }}"></i>
        </button>
        }
    </th>
</ng-template>
