<div class="container">
    <omedom-avatar [avatar]="pro.logo"></omedom-avatar>
    <div>{{ pro.proName }} {{ pro.proFirstName }}</div>
    <div class="text-info-bold">{{ pro.position }}</div>
    <div>{{ pro.name }}</div>

    <div class="opinion-container margin-bottom-1">
        @if(pro.rate ) {
        <div class="rating margin-right-1">
            <i class="uil uil-star"></i>{{ pro.rate }}
        </div>
        }
        <div class="charter">
            <i class="uil uil-check-circle"></i>Charte éthique
        </div>
    </div>

    <div class="value-container">
        @for(value of pro.values; track value){
        <div class="value margin-bottom-1">{{ value }}</div>
        }
    </div>

    <div class="omedom-button-primary" (click)="onDetailClicked($event)">
        <!-- Demander conseil -->
        Voir détail
    </div>
</div>
