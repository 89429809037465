<div class="balance">
    <span class="value">{{ totalBalance | omedomNumber }} €</span>
</div>

<div class="infos">
    <div class="info layout-{{layout}}">
        <span class="title">Total déposé</span>
        <span class="value" id="deposit"
            >{{ totalDeposit | omedomNumber }} €</span
        >
    </div>

    <div class="info layout-{{layout}}">
        <span class="title">Total retiré</span>
        <span class="value" id="withdraw"
            >{{ totalWithdraw | omedomNumber }} €</span
        >
    </div>

    <div class="info layout-{{layout}} important">
        <span class="title">Taux d'intérêt</span>
        <span
            class="value"
            [class.green]="averageInterest > 0"
            [class.orange]="averageInterest < 0"
            >{{ averageInterest | omedomNumber }} %</span
        >
    </div>
</div>

<div class="progress-bar">
    <div class="total-container">
        @for (saving of savings; track saving.uid) {
        <div
            class="progress-value"
            [style.width]="getPercentage(saving) + '%'"
            [style.background]="getColor(saving)"
        ></div>
        }
    </div>
</div>

<div class="legend">
    @for (saving of savings; track saving.uid) {
    <div class="item">
        <div class="color" [style.background]="getColor(saving)"></div>
        <span class="label">{{ saving.name }}</span>
        @if (saving.interestRate) {
        <span class="interest-rate"
            >{{ saving.interestRate | omedomNumber }} %</span
        >
        } @if (saving.balance) {
        <span class="borrowed">{{ saving.balance | omedomNumber}} €</span>
        }
    </div>
    }
</div>
