@if (photo) {
<img [src]="photo" class="avatar" alt="property photo" />
} @else{
<div class="avatar">
    <i class="uil uil-{{ defaultIcon }}"></i>
</div>
} @if (selected && proLogo) {
<div class="icon-container card-view">
    <img class="pro-avatar" [src]="proLogo" />
</div>
}

<div class="bottom-info">
    <div class="card-content">
        <h3>{{ title ?? 'Bien inconnu' }}</h3>
        <p>{{ subTitle }}</p>
    </div>

    @if (selected) {
    <div class="vertical-center">
        <i class="uil-check-circle validate-logo"></i>
    </div>
    }
</div>
