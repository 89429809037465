<omedom-modal-header>Renvoyer la licence</omedom-modal-header>

<div class="container">
  <!-- <form [formGroup]="sendForm" (ngSubmit)="send()"> -->
  <!-- <omedom-input
  [boldLabel]="true"
  formControlName="email"
  label="E-mail"
  placeholder="Renseignez l'e-mail de votre client"
  type="email"
></omedom-input> -->

<!-- <span class="omedom-form-error" *ngIf="errorMessage">{{
errorMessage
}}</span> -->
<!-- <omedom-info
*ngIf="errorUserCantHaveLicence"
class="margin-top-1"
[information]="infoMessageSubscriptionAlreadyValid"
></omedom-info> -->

<p>
  Voulez-vous vraiment renvoyer le mail de présentation de la licence à
  {{ licence.userEmail }} ?
</p>

<button
  (click)="resend()"
  [disabled]="pending"
  class="omedom-button-yellow"
  >
  <i class="uil uil-message"></i>
  Envoyer
  @if (pending) {
    <ion-spinner></ion-spinner>
  }
</button>
<!-- </form> -->
</div>
