<div class="omedom-page-container">
    <div class="omedom-page-container-content">
        @if (treasury) {
        <form #form="ngForm" class="w-100">
            <div class="income-header">
                <i
                    [class]="selectedTreasuryCategory?.icon"
                    class="income-icon"
                ></i>
                <span class="income-title">{{
                    selectedTreasuryCategory?.label
                }}</span>
            </div>
            <!-- <app-input class="margin-top-2"
        name="designation"
        label="Désignation"
        placeholder="Désignation"
        [maxLength]=30
        [(ngModel)]="treasury.designation"
      [disabled]="updateType === treasuryUpdateType.onlyThisOne"></app-input> -->
            <omedom-input
                inputmode="decimal"
                class="margin-top-2"
                id="amount"
                name="amount"
                label="{{
                    selectedTreasuryCategory?.label === 'Loyer'
                        ? 'Loyer perçu (Revenu net)'
                        : 'Montant global'
                }}"
                icon="uil uil-euro"
                [(ngModel)]="amount"
                [debounce]="125"
                (ngModelChange)="formatAmountNumber($event)"
                [required]="true"
                appOmedomNumber
                placeholder="ex : {{ '70,00' | omedomNumber }}"
            ></omedom-input>
            @if (selectedTreasuryCategory?.label === 'Loyer') {
            <omedom-input
                class="margin-top-2"
                id="rentWithCharges"
                name="rentWithCharges"
                label="Loyer hors charges"
                icon="uil uil-euro"
                [(ngModel)]="rentWithCharges"
                [debounce]="125"
                (ngModelChange)="formatAmountNumber($event, 'rentWithCharges')"
                [required]="false"
                appOmedomNumber
                placeholder="ex : {{ '70,00' | omedomNumber }}"
            ></omedom-input>
            } @if (((propertyOptions$ | async) ?? []).length > 0) {
            <omedom-select
                class="margin-top-2"
                name="property"
                label="Associer au bien"
                [(ngModel)]="selectedProperty"
                [placeholder]="propertyPlaceholder"
                [options]="(propertyOptions$ | async) ?? []"
                [disabled]="true"
            ></omedom-select>
            } @if ((treasury?.associatedTo ?? []).length > 0) { @for (property
            of treasury.associatedTo; track property.propertyUid) {
            <omedom-form-associate-to
                [propertyInfo]="property"
                [totalAmount]="$any(amount)"
                [disabled]="false"
            ></omedom-form-associate-to>
            } }
            <hr class="omedom-divider" />
            @if (((periodicityOptions$ | async) ?? []).length > 0) {
            <omedom-select
                label="Périodicité"
                [required]="true"
                name="periodicity"
                [(ngModel)]="selectedPeriodicity"
                [placeholder]="periodicityPlaceholder"
                [options]="(periodicityOptions$ | async) ?? []"
                [disabled]="updateType === treasuryUpdateType.onlyThisOne"
            ></omedom-select>
            } @if (selectedPeriodicity) { @if (selectedPeriodicity !==
            treasuryPeriodicity.punctual) {
            <omedom-input
                class="margin-top-2 w-50 padding-right-1"
                type="date"
                name="startDate"
                label="Date de début"
                placeholder="Date de début"
                [(ngModel)]="startDate"
                [disabled]="true"
                min="1200-01-01"
                max="2080-01-01"
                warning="La “date de début” est obligatoire et non modifiable car elle est liée à votre historique et au calcul de votre trésorerie."
            >
            </omedom-input>
            <omedom-input
                class="margin-top-2 w-50 padding-left-1"
                type="date"
                name="endDate"
                label="Date de fin"
                placeholder="Date de fin"
                [(ngModel)]="endDate"
                min="1200-01-01"
                max="2080-01-01"
                [disabled]="updateType === treasuryUpdateType.onlyThisOne"
            ></omedom-input>
            <omedom-input
                class="margin-top-2 padding-right-1"
                type="number"
                name="debitDate"
                label="Jour d'encaissement"
                placeholder="01"
                [(ngModel)]="displayDebitDate"
                [required]="true"
                [min]="01"
                [max]="31"
                pattern="(0*[1-9]|[12]\d|3[01])"
                [disabled]="updateType === treasuryUpdateType.onlyThisOne"
            ></omedom-input>
            } @if (selectedPeriodicity === treasuryPeriodicity.punctual) {
            <omedom-input
                class="margin-top-2 padding-right-1"
                type="date"
                name="debitDate"
                label="Date d'encaissement"
                placeholder="01"
                [(ngModel)]="debitDate"
                [required]="true"
                min="1200-01-01"
                max="2080-01-01"
                [disabled]="updateType !== treasuryUpdateType.all"
            ></omedom-input>
            } }
            <hr class="omedom-divider" />
            <omedom-input
                class="margin-bottom-2"
                name="notes"
                label="Complément d'information"
                icon="uil uil-notes"
                placeholder="Complément d'information"
                [(ngModel)]="notes"
            ></omedom-input>
            <div class="actions-container">
                <button
                    class="omedom-button-green flex-1"
                    (click)="submit()"
                    [disabled]="form.invalid"
                >
                    <i class="uil uil-save"></i>
                    Enregistrer
                </button>
            </div>
        </form>
        }
    </div>
</div>
